//
// Header
// --------------------------------------------------

header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 99;
  top: 0;

  .megamenu {
    border-radius: 0 0 2rem 2rem !important;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.25);
  }

  #social-bar {
    background-color: #F5F5F5;

    .social {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;

      .email, .phone {
        font-size: 0.9em;
      }

      .flag {
        text-decoration: none;
        padding-left: 3%;
        padding-right: 3%;
        color: #008065;
        vertical-align: middle;

        .fui-mail {
          padding-right: 0.5%;
        }

        .flag-1 {
          background-color: #333F48;
          border-radius: 50%;
          color: #333F48;
          margin-right: 1%;
          margin-top: 0.5%;

          .fa-fw {
            width: 100%;
            padding-top: 5px;
          }
        }

        .flag-2 {
          display: inline-block;
          color: #000;
          vertical-align: middle;
          // margin-right: 2%;
        }
      }

      .network-social {
        display: flex;
        // border-left: 1px solid #ffffff;
        > a {
          text-decoration: none;
        }

        > * {
          width: 1.5rem;
          height: auto;
          overflow: hidden;
          text-align: center;

          &:first-child {
            margin-left: 0;
          }

          > * {
            display: block;
            position: relative;
            top: 0;

            &:first-child {
              background-color: #ffffff;
              padding-bottom: 20%;
              padding-top: 15%;
            }
          }

          &:hover > * {

            background-color: #bbbbbb;
          }
        }
      }
    }

    .wrap-act {
      align-items: center;
      display: flex;
      justify-content: center;

      .search {
        min-width: 32px;
        max-height: 20px;
        margin-right: 10px;
        padding-left: 9px;

        svg {
          vertical-align: unset;
          margin: auto;
        }
      }

      .language {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;

        .space {
          border-left: 1px solid #ffffff;
          margin-right: 1rem;
          content: '';
        }

        .en, .vn {
          padding: 0 6px;
          background-color: #ffffff;
          border-radius: 0.25rem;
          color: #333F48;
          text-decoration: none;
          font-size: 14px;
          text-transform: uppercase;
        }

        > a.active, a:hover {
          color: #ffffff;
          background-color: #E55300;
        }
      }

      > form {
        min-width: 190px;

        .form-group {
          .input-group {
            > input {
              height: 25px;
              border: unset;
              font-style: italic;
              font-size: 11px;
            }

            > a {
              background-color: #ffffff;
              color: #333F48;

              padding: 1% 3% 0% 3%;
              font-size: 0.8em;

              // padding: 0% 3% 0% 3%;
              // font-size: 0.9em;

              border-radius: 0 3px 3px 0;
              border-left: unset;
            }
          }
        }
      }
    }
  }

  #main-menu {
    background-color: #ffffff;

    .navbar {
      // padding-left: 25%;
      // padding-right: 25%;
      .pace {
        min-width: 9rem;
        margin-right: 2rem;
      }

      .kbv {
        padding-top: 0.8rem;
        min-width: 13.6rem;
        margin-left: 2rem;
      }
    }

    .menu-icon {
      height: 30px;
      width: 30px;
      position: absolute;
      z-index: 2;
      right: 0;
      margin-top: 1.5rem;
      margin-right: 1rem;
      cursor: pointer;

      .menu-icon__line-left {
        width: 15px !important;
      }

      .menu-icon__line {
        height: 2px;
        width: 30px;
        display: block;
        background-color: #000;
        margin-bottom: 4px;
        -webkit-transition: background-color .5s ease, -webkit-transform .2s ease;
        transition: background-color .5s ease, -webkit-transform .2s ease;
        transition: transform .2s ease, background-color .5s ease;
        transition: transform .2s ease, background-color .5s ease, -webkit-transform .2s ease;
      }

      .menu-icon__line-right {
        width: 15px;
        float: right;
      }
    }

    .logo {
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: #ffffff;

    }

    .menu-list {
      .menu {
        text-align: center;

        .nav {
          display: inline-flex;
        }

        > ul {
          > li {
            margin-left: 0;
            text-align: center;

            > a {
              text-decoration: none;
              font-weight: 600;
              text-align: center;
              text-transform: uppercase;
              color: #333F48;
              font-size: 15px;
            }

            > a:hover {
              color: #008065;
            }
          }

          > li {
            > a.active {
              background-color: unset;
              color: #008065;
              font-weight: bold;
            }
          }
        }
      }

      .navbar-collapse:not(.show) {
        display: none;
      }
    }
  }
}

header.nav-active {
  min-height: 100% !important;

  .menu-icon__line {
    background-color: #000;
    transform: translateX(0px) rotate(-45deg);
  }

  .menu-icon__line-left {
    transform: translateX(1px) rotate(45deg);
  }

  .menu-icon__line-right {
    transform: translateX(-2px) rotate(45deg);
  }

  .menu {
    > ul {
      > li:nth-child(n+1) {
        // min-width: 100%;
        // padding: 1% 0%;
      }

      > li a {
        //color: #fff;
        z-index: 2;
        font-size: 15px;
        font-weight: 500;
      }

      > li.active {
        background-color: #faebd7;
      }
    }
  }
}

svg.header_info {
  fill: #008065;
}

a.social_icon {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 13px;
  text-align: center;
  -webkit-transition: color .3s ease;
  transition: color .3s ease;
  text-decoration: none;

  &:hover {
    background-color: #bbbbbb !important;
  }

  &:first-child {
    margin-left: 20px;
  }
}
