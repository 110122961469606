/* Fonts */
$font-family-open-sans: 'Open Sans', sans-serif;
$font-family-noto-sans: 'Noto Sans', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-roboto: 'Roboto', sans-serif;
$font-family-barlow: 'Barlow', sans-serif;

/* Mixin */
$mobile-width: 425px;
$tablet-width: 768px;
$desktop-width: 1024px;
$desktop-large-width: 1440px;
$desktop-xl-width: 1920px;

$border-radius:               .375rem;
$border-radius-sm:            .25rem;
$border-radius-lg:            .5rem;
$border-radius-xl:            1rem;
$border-radius-xxl:           2rem;
$border-radius-pill:          50rem;

$border-width:                1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);
$border-style:                solid;

$color-base: #000;
$color-invert: #fff;
$color-primary: #008065;
$color-primary-dark: #005A46;
$color-secondary: #003146;
$color-blue: #005D7D ;
$color-gray: #E0E0E0;
$color-light-gray: #F5F5F5;
$color-light-gray-1: #D1D1CE;
$color-dark-gray: #A9A9AD;
$color-orange: #E55300;

// Typography
$font-primary: 'Noto Sans', sans-serif;
$font-secondary: 'Barlow', sans-serif;
$font-heading: $font-secondary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px

// Transitions
$trans-duration: 0.18s;
$trans-ease: ease-in-out;
$trans: all $trans-duration $trans-ease;

// Grid
$container-size: 90.625rem; // 1450px
$container-size-smallest: 31.25em; // 500px
$container-size-xsmall: 42.5em; // 680px
$container-size-small: 57.5em; // 920px
$container-size-medium: 72.5em; // 1160px
$container-size-large: 117.5rem; // 1880px
$grid-gutter: 20px;
$grid-gutter-em: 1.25em; // Changing grid gutter to em causes a compiling error :/


// $enable-gradients: true;

$button-color: #97d700;
$button-recolor: #97d700;
$light-color: #E5EFF6;
$secondary-color: #C1D8E5;
$gray-color: #F3F3F3;
$button-green: #008065;
$brand-color: #008065;
$dark-color: #003146;
$theme-colors: (
        info: $button-recolor,
        light: $light-color,
        secondary: $secondary-color,
        green: $button-green,
        dark: $dark-color,
        "gray": $gray-color,
);
$container-size: 90.625rem; // 1450px
$grid-gutter: 20px;
$grid-gutter-em: 1.25em; // Changing grid gutter to em causes a compiling error :/

//$custom-control-indicator-size: 1.75rem;
$input-bg: #F2F2F2 !default;
$input-focus-border-color: $button-green !default;
$input-focus-width:       2px !default;
$input-focus-color:       rgba($button-green, .8) !default;
$input-focus-box-shadow:  0 0 0 $input-focus-width $input-focus-color !default;
$btn-font-size: 13px;

/* Mixin */
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-montserrat;
  color: #333F48;
}

h1 {
  font-size: 34px;
  font-weight: 300;
  line-height: 150%;
}

h2 {
  font-size: 30px;
  font-weight: 400;
  line-height: 150%;
}

h3 {
  font-size: 24px;
  font-weight: 450;
  line-height: 150%;
}

h4 {
  font-size: 20px;
  font-weight: 450;
  line-height: 150%;
}

h5 {
  font-size: 18px;
  font-weight: 450;
  line-height: 150%;
}

h6 {
  font-size: 16px;
  font-weight: 450;
  line-height: 150%;
}

$btn-background: #E65300;
$btn-hover: #E87331;
$btn-border: #E65300;
$btn-color: #fff;

$border-width: 1px;

@mixin button-variant($background, $color, $border-color, $border-width, $border-radius, $btn-hover) {
  background-color: $background;
  border-radius: subtract($border-radius, $border-width);
  border-width: $border-width;
  border-color: $border-color;
  border-style: solid;
  color: $color;

  &:hover {
    background-color: $btn-hover;
  }
}

.btn-info {
  @include button-variant($btn-background, $btn-color, $btn-border, $border-width, $border-radius, $btn-hover);
}

.rounded-xl {
   border-radius: 0.5rem!important;
}

.rounded-2xl {
   border-radius: 1.5rem!important;
}
