#event-list {
  .even {
    background-color: #F5F5F5;
  }

  .odd {
    background-color: #F5F5F5;
  }

  .wrap {
    > h4 {
      font-weight: bold;
      font-size: 25px;
      line-height: 150%;
      //text-transform: uppercase;

      > a {
        color: #003146;
        text-decoration: none;

        &:hover {
          color: #005D7D;
        }
      }
    }

    .content-box {
      font-size: 14.5px;
      line-height: 26px;
      text-align: justify;
      color: #333F48;
    }

    .btn-action {
      > a {
        padding: .5% 3%;
      }
    }
  }
}

#event-detail {
  .wrap-box {
    margin: 2% 6.3%;
    padding: 2% 3%;

    > h3 {
      font-family: 'Barlow','Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      //font-size: 2rem;
      line-height: 42px;
      /* or 168% */

      text-align: center;

      color: #008065;
    }

    > h1 {
      font-family: 'Barlow','Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      //font-size: 3rem;
      line-height: 120%;
      /* or 48px */
      /* or 168% */

      color: #003146;
      //text-transform: uppercase;
    }

    .paragraph {
      text-align: justify;
    }

    .event-table {
      .table {
        border-collapse: separate;
        border-spacing: 0;
        //min-width: 350px;

        tr th, tr td {
          border-right: 1px solid #7abdaf;
          border-left: 1px solid #7abdaf;
          padding: 1rem;
        }

        tr th:first-child {
          border-left: 1px solid #7abdaf;
        }

        tr td:first-child {
          border-right: 1px solid #7abdaf;
        }

        tr th {
          background: #eee;
          border-top: 1px solid #7abdaf;
          text-align: left;
        }


        th {
          border-color: #7abdaf;
          background-color: #F5F5F5;
          min-width: 10rem;

          font-style: normal;
          font-weight: 700;
          font-size: 17px;
          line-height: 170%;
          /* or 29px */

          text-align: justify;

          color: #333F48;

        }

        td {
          border-color: #7abdaf;
          font-size: 17px;
          line-height: 170%;
          text-align: justify;
          color: #333F48;

        }

        tr td {
          border-left: 0;
        }

        /* top-left border-radius */
        tr:first-child th:first-child {
          border-top-left-radius: 10px;
          border-top-right-radius: 0px;
        }

        /* top-right border-radius */
        tr:first-child td:last-child {
          border-top-right-radius: 10px;
        }

        /* bottom-left border-radius */
        tr:last-child th:first-child {
          border-bottom-left-radius: 10px;
          border-bottom: 1px solid #7abdaf;
        }

        /* bottom-right border-radius */
        tr:last-child td:last-child {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 0;
          border-bottom: 1px solid #7abdaf;
        }

        @media screen and (max-width: 768px) {

          tr td {
            border-left: 1px solid #7abdaf;
          }

          tr:first-child th:first-child {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }

          tr:first-child td:last-child {
            border-top-right-radius: 0;
          }

          /* bottom-right border-radius */
          tr:last-child td:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          /* bottom-right border-radius */
          tr:last-child th:first-child {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
          }
        }
        //@include media-breakpoint-up(sm) {
        //
        //}
      }
    }

    .btn-action {
      //margin-top: 3%;
      //margin-bottom: 3%;
      > a {
        //background-color: #97D700;
        font-size: 1.2em;
        line-height: 21px;;
        text-decoration: none;
        color: #E65300;
        padding: 1.5% 4.2%;
        display: inline-block;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}