/*KBV Introduce*/
.introKBV {
  //background-color: #005D7D !important;

  .heading {
    color: #fff !important;
  }

  .row_box {
    border-radius: 1.5rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);

    ._box:nth-of-type(1) {
      //border-bottom: 1px solid #dee2e6 !important;
    }
  }

  p {
    text-align: justify;
  }

  h2 {
    color: #003146;
  }

}

.view {
  position: relative;
  cursor: default;
  overflow: hidden;

  > img {
    position: relative;
    opacity: 0.1;
  }

  .mask {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    background-attachment: fixed;
    overflow: hidden;

    .slii-content {
      // padding: 1% 14%;
      font-weight: 450;
    }

    > img {
      max-width: 15rem;
      padding-bottom: 1rem;
    }
  }
}

.overlay {
  .mask {
    -webkit-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
}

#introduce {
  .intro-box {
    // padding: 1rem;
    padding: 2rem 1rem;

    .logo-img {
      > img {
        max-width: 16rem;
      }
    }

    .txt-content {
      text-align: justify;
    }
  }

  .intro-box:hover {
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  }
}

.google_search {
  h3 {
    font-family: $font-family-montserrat;
    font-weight: 300;
    font-size: 34px;
    line-height: 42px;
    color: #333F48;
  }

  #___gcse_0 {
    .gsc-control-cse {
      border: unset;
      background-color: unset;

      .gsc-input {
        padding-right: unset;

        .gsc-input-box {
          border: unset;
          background: unset;

          #gsc-i-id1 {
            background: #FFFFFF;
            border: 1px solid #66AFE9;
            min-height: 3rem;
            padding-left: 15px !important;
          }

          input:focus {
            box-sizing: border-box;
            box-shadow: 0px 0px 12px rgba(102, 175, 233, 0.59);
          }

          .gsib_b {
            display: none;
          }
        }
      }

      .gsc-search-button {
        .gsc-search-button-v2 {
          background-color: #97d700;
          padding: 12px 27px 13px 27px;
          outline: 0;
          border: none;

          &:hover {
            background-color: #97d700;
          }

          svg {
            min-width: 1.5rem;
            min-height: 1.5rem;
            fill: #333F48;
          }
        }
      }
    }
  }
}
