// Large desktop min-width: 1920
@media only screen and (min-width: $desktop-xl-width) {
	.dynamic_banner {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 25rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 31.5rem;
		padding-top: 10.4rem;
	}

	.dynamic_banner .sl_content {
		align-self: center;
		//display: contents;
	}

	.dynamic_banner .box {
		text-align: center;
		//align-self: center;
		p {
			// padding-top: 1rem;
			margin-bottom: 0.5rem;
		}

		h2 {
			font-size: 4.5rem;
			color: #439bcf;
			line-height: 1;

			span.ql-size-large {
				font-weight: 700!important;
				font-size: 5rem;
				margin-left: 1rem;
			}

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				// font-size: 5rem;
				// position: relative;
				left: 0.8rem;
			}

			sup {
				top: -1.5rem;
				font-size: 2rem;
			}
		}

		h4 strong {
			font-size: 2rem;
		}

		h5 {
			font-size: 1.39rem;
			border-top: 1px solid #79a3bf;
			border-bottom: 1px solid #79a3bf;
			display: inline-block;
			padding: 0.5rem 0;
			line-height: 1.4;

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 1.56rem;
				color: #439bcf;
			}
		}

		h6 {
			font-size: 1.5rem;

			strong {
				color: #439bcf;
			}
		}
	}

	.leadership-method .wrap {
		h3 {
			font-weight: 500;
			font-size: 3.1rem;
			line-height: 1;

			span.ql-size-large {
				font-weight: 700!important;
				font-size: 3.4rem;
				margin-left: 1rem;
			}

			strong {
				font-size: 3.6rem;
				left: 1.4rem;
				position: relative;
			}

			strong sup,
			sup,
			sup strong {
				top: -1rem;
				font-size: 50%;
			}
		}

		h4 {
			padding: 0.2rem 0;
			font-size: 1rem;

			strong {
				font-size: 1.1rem;
			}
		}
	}

	html:lang(en) {
		.dynamic_banner {
			.box h2 {
				font-size: 4.2rem;
				position: relative;
				left: 0.8rem;

				strong {
					-webkit-text-stroke-width: thin;
					font-weight: normal;
					// font-size: 5rem;
				}

				sup,
				sup strong {
					font-size: 2rem;
					top: -2rem;
				}
			}

		}

		.leadership-method .wrap h3 {
			// position: relative;
			// left: 0.8rem;
			font-size: 3.2rem;

			span.ql-size-large {
				font-size: 3.2rem;
				margin-left: 0;
			}
			sup {
				// font-size: 1.5rem;
				top: -1.5rem;
			}
		}
	}
	//**** END DYNAMIC BANNER SLIDER
	.plan {
		.wrap {
			width: 50%;
			max-height: 18rem;
			min-height: 18rem;
		}
	}

	#slider-home .wrap-caption {
		margin-bottom: 7rem;
	}

	#slider-contact .wrap-caption {
		margin-bottom: 0;
	}

	#slider-contact .wrap-caption .logo-img > img {
		max-width: 30%;
	}

	#slider-contact .wrap-caption h3,
	#slider-home .wrap-caption h3 {
		font-size: 1.8rem;
		line-height: 1.6;
	}

	#carouselBanner .carousel-indicators > li,
	#carouselBanner .carousel-indicators > li.active {
		width: 1rem;
		height: 1rem;
		border: 1px solid #97d700;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
}
// Large desktop max-width: 1920
@media only screen and (max-width: $desktop-xl-width) {
	.dynamic_banner {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 25rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 22.5rem;
		padding-top: 7rem;
	}

	.dynamic_banner .sl_content {
		align-self: center;
		//display: contents;
	}

	.dynamic_banner .box {
		text-align: center;
		//align-self: center;
		p {
			// padding-top: 1rem;
			margin-bottom: 0.5rem;
		}

		h2 {
			font-size: 3.3rem;
			color: #439bcf;
			line-height: 1;

			span.ql-size-large {
				font-weight: 700!important;
				// font-size: 3.7rem;
				font-size: clamp(3.2rem,calc(100% + #{$dev-width-size}),3.7rem);
		    margin-left: 1rem;
			}

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 3.7rem;
				position: relative;
				left: 0.8rem;
			}

			sup {
				font-size: 1.5rem;
				top: -1rem;
			}
		}

		h4 {
			font-size: 2rem;
		}

		h5 {
			font-size: 1.02rem;
			border-top: 1px solid #79a3bf;
			border-bottom: 1px solid #79a3bf;
			display: inline-block;
			padding: 0.5rem 0;
			line-height: 1.4;

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 1.13rem;
				color: #439bcf;
			}
		}

		h6 {
			strong {
				color: #439bcf;
			}
		}
	}

	html:lang(en) .dynamic_banner {
		.box {
			h2 {
				font-size: 3.25rem;
				position: relative;
				left: 0.7rem;

				span.ql-size-large {
					font-weight: 700!important;
					font-size: 3.25rem;
				}

				sup {
					top: -1.5rem;
				}
			}
		}
		// .sl-logo {
		// 	padding-top: 8.1rem;
		// }
	}

	html:lang(en) .leadership-method .wrap h3 {
		font-size: 2.86rem;
		left: 1rem;

		sup {
			top: -1.5rem;
			// left: -0.2em;
		}
	}
	//**** END DYNAMIC BANNER SLIDER
	.leadership-method .wrap {
		h3 {
			font-weight: 500;
			font-size: 3.2rem;
			line-height: 1;

			span.ql-size-large {
				font-weight: 700!important;
				font-size: 3.46rem;
		    margin-left: 1rem;
			}

			strong {
				font-size: 3.2rem;
				letter-spacing: 0.2rem;
				position: relative;
				left: 0.7rem;
			}

			strong sup,
			sup,
			sup strong {
				top: -1rem;
				font-size: 50%;
			}
		}

		h4 {
			padding: 0.2rem 0;
			font-size: 1rem;

			strong {
				font-size: 1.1rem;
			}
		}
	}

	.plan {
		.wrap {
			width: 50%;
			max-height: 18rem;
			min-height: 18rem;
		}
	}

	#slider-home .wrap-caption {
		margin-bottom: 7rem;
	}

	#slider-contact .wrap-caption {
		margin-bottom: 0;
	}

	#slider-contact .wrap-caption .logo-img > img {
		max-width: 30%;
	}

	#slider-contact .wrap-caption h3,
	#slider-home .wrap-caption h3 {
		font-size: 1.8rem;
		line-height: 1.6;
	}

	#carouselBanner .carousel-indicators > li,
	#carouselBanner .carousel-indicators > li.active {
		width: 1rem;
		height: 1rem;
		border: 1px solid #97d700;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}
}
// Large desktop max-width: 1880px
@media only screen and (max-width: 1880px) {
	.dynamic_banner .sl-logo {
		padding-left: 22.5rem;
		padding-top: 7rem;
	}
	// .dynamic_banner .box h2 {
	// 	font-size: 3.3rem;
	// }
	//
	// .dynamic_banner .box h2 {
	// 	font-size: 3.7rem;
	// 	position: relative;
	// 	// left: 0.8rem;
	// }
	//
	// .dynamic_banner .box h5 {
	// 	font-size: 1.02rem;
	// }
	// // .dynamic_banner .box h5 strong {
	// // 	font-size: 1.14rem;
	// // }
	// html:lang(en) .dynamic_banner .box h2 {
	// 	// font-size: 3.2rem;
	// }
}
// Large desktop max-width: 1780px
@media only screen and (max-width: 1780px) {
	.dynamic_banner .sl-logo {
		padding-left: 20rem;
		padding-top: 5.8rem;
	}
	// .dynamic_banner .box h2 {
	// 	font-size: 3rem;
	// }
	// // .dynamic_banner .box h2 strong {
	// // 	font-size: 3.3rem;
	// // 	position: relative;
	// // 	left: 0.8rem;
	// // }
	// .dynamic_banner .box h5 {
	// 	font-size: 0.92rem;
	// }
	// // .dynamic_banner .box h5 strong {
	// // 	font-size: 1.03rem;
	// // }
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 2.95rem;
	// }
}
// Large desktop max-width: 1680px
@media only screen and (max-width: 1680px) {
	// .dynamic_banner .box h2 strong {
	// 	font-size: 3.26rem;
	// }
	//
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 2.9rem;
	// }
	//
	// html:lang(en) .dynamic_banner .sl-logo {
	// 	padding-top: 6.5rem;
	// }
}
// Large desktop max-width: 1650px
@media only screen and (max-width: 1650px) {
	.dynamic_banner .sl-logo {
		padding-left: 18.2rem;
		padding-top: 4.8rem;
		padding-right: 0;
	}
	// .dynamic_banner .box h2 {
	// 	font-size: 2.6rem;
	// 	color: #439bcf;
	// 	line-height: 1;
	// }
	//
	// .dynamic_banner .box h2 strong {
	// 	font-size: 2.9rem;
	// 	position: relative;
	// 	left: 0.8rem;
	//
	// 	sup {
	// 		font-size: 1rem;
	// 	}
	// }
	//
	// .dynamic_banner .box h5 {
	// 	font-size: 0.8rem;
	// 	border-top: 1px solid #79a3bf;
	// 	border-bottom: 1px solid #79a3bf;
	// 	display: inline-block;
	// 	padding: 0.5rem 0;
	// 	line-height: 1.4;
	// }
	//
	// .dynamic_banner .box h5 strong {
	// 	font-size: 0.9rem;
	// 	color: #439bcf;
	// }
	//
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 2.59rem;
	// 	left: 0.5rem;
	// }
	//
	// html:lang(en) .dynamic_banner .sl-logo {
	// 	padding-top: 5.3rem;
	// }
	//**** END DYNAMIC BANNER SLIDER
	// .leadership-method .wrap h3 {
	// 	sup {
	// 		font-size: 1rem;
	// 	}
	// }

	.plan {
		.wrap {
			width: 50%;
			max-height: 18rem;
			min-height: 18rem;
		}
	}

	#slider-home .wrap-caption {
		margin-bottom: 7rem;
	}

	#slider-contact .wrap-caption {
		margin-bottom: 0;
	}

	#slider-contact .wrap-caption .logo-img > img {
		max-width: 30%;
	}

	#slider-contact .wrap-caption h3,
	#slider-home .wrap-caption h3 {
		font-size: 1.8rem;
		line-height: 1.6;
	}

	#carouselBanner .carousel-indicators > li,
	#carouselBanner .carousel-indicators > li.active {
		width: 1rem;
		height: 1rem;
		border: 1px solid #97d700;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	html:lang(en) #SLII-slider-caption .wrap-box .wrap .wrap-txt h2 {
		letter-spacing: 0;
	}

	// html:lang(en) .slider_dynamic_banner .slii h2 {
	// 	font-size: 3rem;
	// }

	html:lang(en) #SLII-slider-caption .wrap-box .wrap .wrap-txt h2 {
		font-size: 2.8rem;
	}
}
// Large desktop max-width: 1500px
@media only screen and (max-width: 1500px) {
	// .dynamic_banner .box h2 {
	// 	font-size: 2.8rem;
	// }
	//
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 2.5rem;
	// }
}
// Medium desktop max-width: 1440
@media only screen and (max-width: $desktop-large-width) {
	#knowledge-list .article-special .box>h4 {
        font-size: 1.4rem;
    }
    // ** End Kho Học Liệu

	.dynamic_banner .slii {
		align-self: center;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 27rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 16.5rem;
		padding-top: 4.3rem;
	}

	.dynamic_banner .box {
		h2 {
			font-size: 2.4rem;
			color: #439bcf;
			line-height: 1;

			span.ql-size-large {
				// font-size: 2.7rem;
				font-size: clamp(2.4rem,calc(100% + #{$dev-width-size}),2.7rem);
				margin-left: 1rem;
			}

			strong {
				font-size: 2.64rem;
				position: relative;
				left: 0.6rem;
			}

			sup,
			sup strong {
				font-size: 1rem;
				top: -1.2rem;
			}
		}

		h5 {
			font-size: 0.74rem;
			padding: 0.5rem 0;

			strong {
				font-size: 0.82rem;
				color: #439bcf;
			}
		}
	}

	.dynamic_banner .box .btn_flat {
		padding: 0.2rem 1rem;
		min-width: unset;
	}

	html:lang(en) .dynamic_banner {
		.sl-logo {
			padding-top: 4.5rem;
		}

		.box h2 {
			font-size: 2.3rem;
			left: 0.5rem;
			span.ql-size-large {
				font-size: 2.4rem;
		    margin-left: 0;
			}
		}
	}
	//**** END DYNAMIC BANNER SLIDER
	.slider_dynamic_banner {
		.sl_logo {
			margin-top: 2.8rem;
			padding: 0;
		}

		.sl_content {
			margin-top: 2.3rem;
		}

		.slii {
			h2 {
				font-size: 2.7rem;

				strong {
					font-size: 2.7rem;
				}
			}

			h5 {
				font-size: 0.85rem;

				strong {
					font-size: 0.95rem;
				}
			}

			h6 {
				font-size: 1rem;
			}
		}
	}

	#slider-contact .wrap-caption .logo-img > img,
	#slider-home .wrap-caption .logo-img > img {
		max-width: 40%;
	}

	#slider-contact .wrap-caption h3,
	#slider-home .wrap-caption h3 {
		font-size: 1.6rem;
	}

	#carouselFooterBanner {
		#SLII-slider-caption {
			.wrap-box {
				padding: 0;

				.logo-img {
					align-self: flex-start!important;
					padding-top: 0.5rem;

					> img {
						max-width: 16.5rem;
						min-width: 16.5rem;
						height: auto;
					}
				}
			}
		}
	}

	html:lang(en) #SLII-slider-caption .wrap-box .wrap .wrap-txt h2 strong {
		font-size: 2.8rem;
		left: 1rem;
	}

	html:lang(en) #SLII-slider-caption .wrap-box .wrap .wrap-txt h2 {
		sup {
			top: -1em;
			left: -.1em;
		}
	}

	.leadership-method .wrap h3 {
			font-size: 3.2rem;
			span.ql-size-large {
				font-weight: 700!important;
				font-size: 3.7rem;
		    margin-left: 1rem;
			}
			sup {
				font-size:2rem;
			}
	}

	html:lang(en) .leadership-method .wrap h3 {
			font-size: 3.15rem;
			margin-left: 0;

			span.ql-size-large {
				font-size: 3.15rem;
    		margin-left: 1rem;
			}

			sup {
				top: -1.5rem;
			}
		}
}
// Medium desktop max-width: 1350
@media only screen and (max-width: 1350px) {
	.dynamic_banner .sl-logo {
		padding-left: 16rem;
		padding-top: 4rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 2.3rem;
	// 		color: #439bcf;
	// 		line-height: 1;
	//
	// 		strong {
	// 			font-size: 2.5rem;
	// 			position: relative;
	// 			left: 0.6rem;
	// 		}
	//
	// 		sup {
	// 			top: -1rem;
	// 		}
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.7rem;
	// 		padding: 0.5rem 0;
	//
	// 		strong {
	// 			font-size: 0.78rem;
	// 			color: #439bcf;
	// 		}
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.sl-logo {
	// 		padding-top: 4.7rem;
	// 	}
	//
	// 	.box h2 {
	// 		font-size: 2.25rem;
	// 		// sup {
	// 		// 	top: -1.2rem;
	// 		// }
	// 	}
	// }
	//**** END DYNAMIC BANNER SLIDER
}
// Medium desktop max-width: 1300px
@media only screen and (max-width: 1300px) {
	.dynamic_banner .sl-logo {
		padding-left: 15.5rem;
		padding-top: 3.75rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 2.3rem;
	// 		color: #439bcf;
	// 		line-height: 1;
	//
	// 		strong {
	// 			font-size: 2.54rem;
	// 			position: relative;
	// 			left: 0.6rem;
	// 		}
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.71rem;
	// 		padding: 0.5rem 0;
	//
	// 		strong {
	// 			font-size: 0.79rem;
	// 			color: #439bcf;
	// 		}
	// 	}
	//
	// 	.btn_flat {
	// 		padding: 0.2rem 1rem;
	// 		min-width: unset;
	// 		font-size: 0.9rem;
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.sl-logo {
	// 		padding-top: 4.4rem;
	// 	}
	//
	// 	.box h2 {
	// 		font-size: 2.26rem;
	// 		left: 0.5rem;
	// 	}
	// }
	//**** END DYNAMIC BANNER SLIDER
}
// Medium desktop max-width: 1280
@media only screen and (max-width: 1280px) {
	.dynamic_banner .sl_logo {
		padding: 2rem 28rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 15.7rem;
		padding-top: 3.9rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 2.15rem;
	// 		color: #439bcf;
	// 		line-height: 1;
	//
	// 		strong {
	// 			font-size: 2.33rem;
	// 			position: relative;
	// 			left: 0.4rem;
	// 		}
	//
	// 		sup {
	// 			font-size: 0.9rem;
	// 			top: -1.2rem;
	// 		}
	// 	}
	//
	// 	h4 {
	// 		font-size: 1.7rem;
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.67rem;
	//
	// 		strong {
	// 			font-size: 0.75rem;
	// 			color: #439bcf;
	// 		}
	// 	}
	//
	// 	h6 strong {
	// 		color: #439bcf;
	// 	}
	//
	// 	.btn_flat {
	// 		padding: 0.2rem 1rem;
	// 		min-width: unset;
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.box h2 {
	// 		font-size: 2.15rem;
	// 		left: 0.5rem;
	//
	// 		sup {
	// 			top: -0.9rem;
	// 		}
	// 	}
	//
	// 	.sl-logo {
	// 		padding-top: 4rem;
	// 	}
	// }
	//**** END DYNAMIC BANNER SLIDER
	// .leadership-method .wrap {
	// 	h3 {
	// 		font-weight: 500;
	// 		font-size: 3.5rem;
	// 		line-height: 1.1;
	//
	// 		strong {
	// 			font-size: 3.7rem;
	// 			letter-spacing: 0.12rem;
	// 		}
	// 	}
	//
	// 	h4 {
	// 		padding: 0.2rem 0;
	// 		font-size: 1.08rem;
	//
	// 		strong {
	// 			font-size: 1.2rem;
	// 		}
	// 	}
	// }

	.view .mask > img {
		max-width: 12rem;
	}

	.plan {
		.wrap {
			width: 56%;
			max-height: 18rem;
			min-height: 18rem;
		}
	}

	#slider-home {
		.wrap-caption {
			h3 {
				font-size: 1.5em;
				line-height: 1.6;
			}
		}
	}

	.slider_dynamic_banner {
		.sl_logo {
			margin-top: 1.5rem;
			padding: 0;
		}

		.sl_content {
			margin-top: 1rem;
		}

		.slii {
			h2 {
				font-size: 2.4rem;
				font-weight: 500;

				strong {
					font-size: 2.5rem;
					letter-spacing: 0.1rem;
					padding-left: 1rem;
				}
			}

			h5 {
				font-size: 0.74rem;
				line-height: 1.6;

				strong {
					font-size: 0.83rem;
				}
			}

			h6 {
				font-size: 1em;
			}
		}
	}

	#carouselFooterBanner {
		#SLII-slider-caption {
			.wrap-box {
				padding: 0;
				margin-right: 8rem;

				.logo-img {
					align-self: flex-start!important;
					padding-top: 0.5rem;

					> img {
						max-width: 16.5rem;
						min-width: 16.5rem;
						height: auto;
					}
				}
			}
		}
	}

	#slider-contact .wrap-caption .logo-img > img,
	#slider-home .wrap-caption .logo-img > img {
		max-width: 50%;
	}
	//
	// html:lang(en) .slider_dynamic_banner .slii h2 {
	// 	sup {
	// 		top: -1.2em;
	// 		font-size: 0.4em;
	// 		left: -0.1em;
	// 	}
	// 	font-size: 2.22rem;
	// }
	html:lang(en) .leadership-method .wrap h3 {
		sup {
			top: -1.6em;
			font-size: 0.4em;
		}

		strong {
			font-size: 3.3rem;
		}
	}
}
// Large desktop max-width: 1200px
@media only screen and (max-width: 1200px) {
	.dynamic_banner .sl_logo {
		padding: 2rem 23rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 14.5rem;
		padding-top: 3.5rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 2rem;
	//
	// 		strong {
	// 			font-size: 2.2rem;
	// 		}
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.63rem;
	//
	// 		strong {
	// 			font-size: 0.7rem;
	// 		}
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.box h2 {
	// 		font-size: 2rem;
	// 	}
	//
	// 	.sl-logo {
	// 		padding-top: 4rem;
	// 	}
	// }
}
