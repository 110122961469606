//.plyr {
//  border: 0.75px solid #008165;
//  border-radius: 5px;
//}

.item-detail .display-box .video-iframe .plyr {

  .plyr__control--overlaid {
    opacity: 0.5;
    background-color: #000000;

    &hover {
      background-color: #ff0000;
    }
  }

  .plyr__video-wrapper .plyr__poster {
    background-color: unset;
    background-position: unset;
    background-size: 100% 100%;
  }
}

// ** End new css
.banner-default {
  height: auto !important;
}

.item-detail .display-box .video-iframe .wrapper img {
  height: 100%;
}

header #main-menu {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

#knowledge-tab {
  background-color: #ffffff;
}

// **
.grecaptcha-badge {
  z-index: 1;
}

#back-to-top-new {
  right: -60px;
}

#back-to-top-new.show {
  right: 0px;
}

.button-component, .button-component * {
  box-sizing: border-box !important;
  outline: none !important;
}

.button-component {
  position: fixed;
  z-index: 999999;
  display: inline-flex;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  left: auto;
  right: 0px;
  //top: 82.5%;
  transform: translateY(-50%);
  bottom: 80px;
  -webkit-transition: all 0.5s ease-out;

  .button-inner {
    transform-origin: 100% 50%;
    transform: rotate(90deg) translate(50%, 50%);
    padding: 4.66667px 9.33334px;
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    border-radius: 50px;
    top: 0px;
    backface-visibility: hidden;
    background-color: rgb(255, 255, 255);
    //background-color: transparent;
    color: rgb(0, 0, 0);
    margin: 10px;

    .button-second-inner {
      flex-direction: row-reverse;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      flex-direction: row;

      .icon-component {
        fill: rgb(0, 0, 0);
        width: 31.5px;
        height: 31.5px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        z-index: 2;
        transition: all 0.3s ease 0s;
        transform: rotate(-90deg);
      }

      .button-text {
        transform: rotate(180deg);
        //transform: translate3d(0px, 0px, 0px);
        backface-visibility: hidden;
        position: relative;
        font-size: 14px;
        font-weight: bold;
        line-height: 16.8px;
        z-index: 2;
        transition: all 0.3s ease 0s;
        top: 0px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        //padding: 0px 9.33334px 0px 4.66667px;
      }
    }
  }

  .button-inner::before {
    position: absolute;
    content: " ";
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgb(0, 0, 0);
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease 0s;
    border-radius: inherit;
    pointer-events: none;
  }

  .button-inner::after {
    position: absolute;
    content: " ";
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    border-radius: inherit;
    pointer-events: none;
    border: 2px solid rgba(0, 0, 0, 0.35);
  }

  .button-inner:hover {
    background-color: #ededed;
    margin-bottom: 1rem;
  }
}

.msgSuccess {
  > h2, h3, h4, h5 {
    text-align: center;
    color: #003768 !important;
  }

  > p {
    > a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.btn-action {
  display: flex;

  > a {
    text-transform: uppercase;
    color: #333F48;
    background-color: #97D700;
    border: unset;
    text-decoration: none;
    font-size: 0.9em;
    padding: 1% 4%;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.btn_flat {
  border-radius: unset;
  min-width: 12rem;
  padding: 0.4rem 0.75rem;
  text-transform: uppercase;
}

.btn-action-br {
  display: flex;

  > a {
    background-color: #97D700;
    padding: 2% 8%;
    margin-left: unset !important;
    font-size: 0.8rem;
    text-transform: uppercase;
    text-decoration: none;
    color: #333F48;

    &:hover {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

#back-to-top {
  visibility: hidden;
  background-color: #2aabe1;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 48px;
  position: fixed;
  bottom: 65px;
  right: 25px;
  z-index: 90;
  cursor: pointer;
  opacity: 0;
  -webkit-transform: translateZ(0);
  transition: all .6s;
  box-shadow: 0 5px 20px rgba(0, 0, 0, .2);

  &:hover {
    background-color: #325d88;
  }

  .fa-angle-up {
    &:before {
      font-size: 1.7rem;
    }
  }
}

#back-to-top.show {
  visibility: visible;
  bottom: 90px;
  opacity: 1;
}

#page-title {
  .intro-title {
    > h3 {
      font-family: $font-family-montserrat;
      font-weight: 300;
      font-size: 34px;
      line-height: 42px;
      color: #333F48;
    }
  }
}

.gender .invalid-feedback, .gender .valid-feedback {
  position: relative;
  min-width: 200px;
  right: 237%;
  text-align: left;
}

.signUpConsultation {
  .modal-content {
    top: 65px;

    .consultation-form {
      .modal-header {
        border-bottom: unset;

        .wrap-bt {
          z-index: 1;

          > button {
            background-color: #bbbbbb;
            margin-right: 0.1rem;
            margin-top: 0.1rem;
            padding: 0.35rem 0.7rem;
          }
        }

        .block-reply, .block-form-title {
          > h5 {
            font-family: $font-family-montserrat;
            color: #333f48;
            font-size: 1.3em;

            > span {
              background-color: #333f48;
              color: #ffffff;
              padding: 0.2rem 0.5rem;
            }
          }

          > h6 {
            font-size: 1.2em;
            color: #333f48;
            line-height: 1.2;
          }

          > p {
            font-size: 1em;
            color: #333f48;
            margin-bottom: 0.3rem;
            text0align: justify;
          }
        }

        .block-reply {
          display: none;
        }
      }

      .modal-body {
        // padding: 0 10rem;

        .modal-form {
          ul.fade:not(.show) {
            display: none;
          }

          ul {
            margin-right: -5px;
            margin-left: -5px;

            li {
              list-style: none;
            }
          }

          .spinner-border {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }

          .gender .form-check .invalid-feedback, .gender .form-check .valid-feedback {
            position: relative;
            min-width: 200px;
            right: 237%;
          }

          .consultation-form-send {
            background-color: #97d700;

            &:hover {
              color: #ffffff;
            }
          }
        }

        .block-reply {
          > p {
            > a {
              text-decoration: underline;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

// Location 2 //
.block_slogan_small {
  background-color: #333F48;
  color: #ffffff;
  text-align: center;
  font-family: $font-family-roboto;

  .wrap {
    > p {
      // margin-bottom: 0.4%;
    }
  }
}

.block_slogan_big {
  background-color: #F2F7FB;
  color: #000000;
  text-align: justify;

  .wrap {
    color: #333f48;

  }
}


// Location 3 + Location 4 //
.leadership-method {
  .wrap {
    // padding:0rem 18rem;
    // padding:0 24.7%;
    img {
      //padding: 5% 20%;
    }

    .ld-1 {
      font-weight: 600;
      font-size: 35px;
      line-height: 1.2;
      color: #439BCF;
    }

    h3 {
      font-weight: 600;
      font-size: 35px;
      line-height: 1.2;
      color: #439BCF;
      padding-bottom: 0.5rem;

      > strong {
        font-size: 1.3em;
      }
    }

    h4 {
      display: inline-table;
      font-weight: 500;
      font-size: 15.9px;
      color: #333F48;

      margin-bottom: 1rem;
      padding-bottom: 1%;
    }

    h4 {
      border-top: 1px solid rgba(0, 0, 0, .1);
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      padding: 1rem 0rem;
      line-height: 2rem;
    }

    h4 strong {
      font-size: 19px;
      color: #008065;
      margin-top: 1rem;
      padding: 2% 0%;
      //border-top: 1px solid rgba(0,0,0,.1);
      //border-bottom: 1px solid rgba(0,0,0,.1);
    }

    .ld-4, p {
      padding-top: 1%;
      font-size: 1.1em;
      color: #333F48;
    }

    //.ld-5, h5 {
    //font-weight: 600;
    //font-size: 34.5px;
    //color: #008065;
    //padding-top: 1%;
    //}
    h5 {
      font-weight: 700;
      font-size: 1.5em;
      color: #333F48;
    }

    h5, h6 {
      width: 100%;
    }

    .ld-6, h6 {
      font-weight: 600;
      font-size: 19px;
      color: #008065;
    }
  }
}

// Location 5 //
.leadership_benefit {
  .container {
    > h3 {
      > u {
        background-color: #333F48;
        color: #ffffff;
        text-decoration-line: none;
      }
    }
  }

  .bn-txt {
    font-family: $font-family-montserrat;
    font-weight: 300;
    font-size: 34px;
    line-height: 150%;
    color: #333F48;

    .bg-txt {
      background-color: #333F48;
      color: #ffffff;
    }
  }

  h3 {
    font-family: $font-family-montserrat;
    font-weight: 300;
    font-size: 34px;
    line-height: 150%;
    color: #333F48;

    u {
      background-color: #333F48;
      color: #ffffff;
      text-decoration: none;
    }
  }

  .nb-icon {
    padding: 5% 0;
  }

  .nb-text-item {
    font-size: 15px;
    line-height: 26px;
    color: #333F48;

    > p {
      margin-bottom: 0.1rem;
    }
  }
}

// Location 6 //
.plan {
  min-height: 400px;

  .container-wrap .left, .container-wrap .right {
    min-height: 400px;
    float: left;
  }

  .wrap {
    min-height: 21rem;
    padding: 5rem 3rem;

    // min-height: 443px;
    // padding: 3rem;
    background-color: rgba(255, 255, 255, 0.8);
    float: right;
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
    width: 50%;

    .pl-title {
      font-family: $font-family-montserrat;
      font-size: 28px;
      line-height: 42px;
      color: #333F48;

      > span {
        background-color: #333F48;
        color: #ffffff

      }

      > u {
        background-color: #333F48;
        color: #ffffff;
        text-decoration: none;
      }
    }

    h3 {
      font-family: $font-family-montserrat;
      font-size: 34px;
      line-height: 42px;
      color: #333F48;

      > span {
        background-color: #333F48;
        color: #ffffff;

        u {
          text-decoration: none;
        }
      }

      > u {
        background-color: #333F48;
        color: #ffffff;
        text-decoration: none;
      }
    }

    .pl-content {
      line-height: 26px;
      color: #333F48;
    }
  }

  .plan-bg {
    position: absolute;

    > img {
      opacity: 0.5;
      height: 18rem;
      width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}

// Location 7 //
.schedule_open {
  h2 {
    color: #003146;
  }

  .border_kg {
    background: #003146;
    border: 1px solid #7BAFD4;
    border-radius: 7px;
  }

  ._box_course  {
    background: #FFFFFF;
    border: 1px solid #C1D8E6;
    box-shadow: 7px 6px 10px rgba(7, 35, 62, 0.05);
    border-radius: 30px;
  }

  .title {
    > h3 {
      font-family: 'Barlow','Montserrat', sans-serif;
      font-weight: 300;
      font-size: 34px;
      line-height: 42px;
      /* identical to box height, or 124% */
      text-align: center;
      color: #008065;
    }
  }

  .date {
    padding-top: 2.3rem;

    .bg {
      margin: 0 1.9rem;
      padding: 1rem 0;
      //box-shadow: 2px 2px 2px 2px #008065;
      background: #003146;
      border: 1px solid #7BAFD4;
      border-radius: 7px;

      .square {
        font-family: 'Barlow','Montserrat', sans-serif;
        font-size: 27px;
        line-height: 120%;
        color: #E5EFF6;
        display: inline-grid;
      }
    }
  }

  .heading {
    > h4 {
      font-size: 20px;
      line-height: 42px;
      color: #008065;
    }
  }

  .content table tbody tr th, .content table tbody tr td {
    font-size: 17px;
    line-height: 186%;
    color: #333F48;
    padding: 0.2rem;
  }

  .register {
    .course-act {
      > a {
        background-color: #97D700;
        font-size: 17px;
        line-height: 23px;
        color: #333F48;
        padding: 0.6rem 3rem;
        display: block;
        margin: 1.4rem 6.5rem;
        text-transform: uppercase;
        border-color: #7cb100;

        &:hover {
          color: #fff;
          text-decoration: none;
          background-color: #7cb100;
        }
      }

      > a:nth-child(1) {
        margin-top: 0;
      }
    }

    .course-vat {
      font-size: 12px;
      line-height: 20px;
      color: #333F48;
    }
  }

  // .register::before {
  //     content: "";
  //     border-left: solid 1px #C1D8E5;
  //     min-height: 12rem;
  //     position: absolute;
  //     top: 1rem;
  // }
}

// Location 8 //
#all_ready {
  color: #ffffff;

  .ar-title {
    font-family: $font-family-montserrat;
    font-weight: 300;
    font-size: 34px;
    line-height: 42px;
  }

  .ar-content {
    font-size: 15px;
    line-height: 26px;
    padding: 0 20%;
  }
}
