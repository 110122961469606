#scroll_tab {
  background: #D1F0DC;
  //background: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  position: -webkit-sticky;
  position: sticky;
  z-index: 98;
  top: 5.7rem;
  //top: 12.5rem;
  .subNav {
    .nav {
      margin-left: 1rem;
      display: inline-flex;
      width: 100%;
      text-align: center;
      padding: 0.5rem 0;
      vertical-align: middle;

      > a {
        font-size: 13px;
        line-height: 26px;
        text-transform: uppercase;
        color: #333F48;
        text-decoration: none;

        &:hover {
          background-color: #008065;
          color: #ffffff;
          border-radius: 15px;
        }
      }

      .active {
        //background-color: #008065;
        font-weight: bold;
        //color: #ffffff;
      }

      .nav-link {
        // padding: 0 2%;
        padding: 0 1rem;
      }
    }
  }
}

.model_construction {
  .right-tile {
    font-family: 'Barlow','Montserrat', sans-serif;

    > h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      color: #008065;
    }

    > h3 {
      font-weight: 600;
      font-size: 32px;
      line-height: 42px;
      color: #333F48;
    }
  }

  .wrap-box-content {
    h5 {
      font-weight: bold;
      font-size: 15px;
      line-height: 26px;
      text-transform: uppercase;
      color: #008065;
      border-left: solid 5px #008065;
    }
  }
}

#pg-powering-inspired {
  position: relative;
  cursor: default;
  overflow: hidden;

  > img {
    position: relative;
    opacity: 0.1;
    object-fit: cover;
    object-position: center;
    min-height: 42rem;
  }

  .mask {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    background-attachment: fixed;
    overflow: hidden;
  }
}
