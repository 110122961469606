/* Tablet (Ipad) max-width: 1024px */
@media only screen and (max-width: $desktop-width) {
	header #social-bar .social .email,
	header #social-bar .social .phone {
		font-size: 0.8em;
	}
	//** SOCIAL BAR
	.dynamic_banner .sl-logo {
		padding-left: 13.2rem;
		padding-top: 3rem;
		padding-right: 0;
	}

	.dynamic_banner .box {
		h2 {
			// font-size: 1.75rem;
			font-size: clamp(1.7rem,calc(100% + #{$dev-width-size}),1.8rem)!important;
			color: #439bcf;
			line-height: 1;

			span.ql-size-large {
				// font-size: 1.85rem;
				font-size: clamp(1.75rem,calc(100% + #{$dev-width-size}),1.85rem)!important;
				margin-left: 0;
			}

			strong {
				font-size: 1.88rem;
				position: relative;
				left: 0.4rem;
			}

			sup {
				font-size: 1rem;
				top: -0.6rem;
			}
		}

		h5 {
			font-size: 0.54rem;
			padding: 0.2rem 0;

			strong {
				font-size: 0.6rem;
				color: #439bcf;
			}
		}

		h6 {
			font-size: 0.8rem;
		}
	}

	.leadership-method .wrap {
		h3 {
			// font-size: 3.5rem;
			font-size: clamp(3.3rem,calc(100% + #{$dev-width-size}),3.5rem)!important;
			span.ql-size-large {
				// font-size: 3.9rem;
				font-size: clamp(3.5rem,calc(100% + #{$dev-width-size}),3.7rem)!important;
				margin-left: 0.5rem;
			}
			sup {
				font-size: 1.5rem;
				top:-1.5rem;
			}
		}
	}

	.dynamic_banner .box .btn_flat {
		min-width: 1rem;
		font-size: 0.8rem;
		padding: 0.2rem 1rem;
	}

	html:lang(en) .leadership-method .wrap h3 {
		// font-size: 3.4rem;
		font-size: clamp(3.3rem,calc(100% + #{$dev-width-size}),3.5rem)!important;
		margin-left: .5rem;
		span.ql-size-large {
			// font-size: 3.4rem;
			font-size: clamp(3.2rem,calc(100% + #{$dev-width-size}),3.4rem)!important;
			margin-left: 0.5rem;
		}
	}

	html:lang(en) .dynamic_banner {
		.sl-logo {
			padding-top: 3rem;
		}

		.box h2 {
			font-size: 1.7rem;
			span.ql-size-large {
				// font-size: 1.7rem;
				font-size: clamp(1.6rem,calc(100% + #{$dev-width-size}),1.7rem)!important;
				margin-left: 0;
			}
			sup {
				top: -1rem;
			}
		}
	}
	//**** END DYNAMIC BANNER SLIDER

	.button-component .button-inner .button-second-inner {
		.button-text {
	        font-size: 0.7rem;
	    }
	    .icon-component {
	        width: 1.5rem;
	        height: 1.5rem;
	    }
	}
    // ** End Scroll button

	#scroll_tab .subNav .nav > a,
	header #main-menu .menu-list .menu > ul > li > a {
		font-size: 0.6rem;
	}

	.view .mask > img {
		//max-width: 12rem;
		min-height: 28rem;
	}

	#knowledge-tab,
	#scroll_tab {
		//top:11.5rem;
		top: 4.8rem;
	}

	#pg-powering-inspired {
		> img {
			min-height: 42rem;
		}

		.mask {
			padding: 1rem 3rem;

			img {
				min-height: 34rem;
			}
		}
	}

	.btn-action a {
		font-size: 0.6em;
		padding: 1% 5%;
	}

	nav {
		#main-menu {}
	}

	#carouselBanner .carousel-indicators > li,
	#carouselBanner .carousel-indicators > li.active {
		width: 1rem;
		height: 1rem;
		margin-left: 0.2rem;
		margin-right: 0.2rem;
	}

	.schedule_open {
		.register {
			.border-top {
				// border:0!important;
			}
		}
	}
	// Silder
	#slider-home {
		.wrap-caption {
			h3 {
				font-size: 1.2rem;
			}
		}
	}

	#slider-contact .wrap-caption .logo-img > img,
	#slider-home .wrap-caption .logo-img > img {
		max-width: 40%;
	}

	#slider-contact .wrap-caption,
	#slider-home .wrap-caption {
		margin-bottom: 4rem;
	}

	.plan {
		.wrap {
			max-height: 18rem;
			min-height: 18rem;
		}
	}
	//EOL: Media 1024
}
@media only screen and (max-width : 990px) {
	.dynamic_banner .box .btn_flat {
		font-size: 0.7rem;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 18rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 12.8rem;
		padding-top: 3rem;
	}

	.text_banner_slides {
		font-size: 1rem;
	}

	.dynamic_banner .btn_flat {
		font-size: 0.6rem;
		min-width: 8rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 1.65rem;
	// 		strong {
	// 			font-size: 1.85rem;
	// 		}
	// 	}
	// 	h4 {
	// 		font-size: 1.5rem;
	// 	}
	// 	h5 {
	// 		font-size: 0.52rem;
	// 		strong {
	// 			font-size: 0.58rem;
	// 		}
	// 	}
	// 	h6 {
	// 		font-size: 0.7rem;
	// 	}
	// 	p {
	// 		font-size: 0.6rem;
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 1.65rem;
	// }
}
@media only screen and (max-width : 910px) {
	.dynamic_banner .sl-logo {
		padding-left: 11.5rem;
		padding-top: 2.5rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 1.55rem;
	//
	// 		strong {
	// 			font-size: 1.7rem;
	// 		}
	//
	// 		strong sup,
	// 		sup,
	// 		sup strong {
	// 			font-size: 0.54rem;
	// 			top: -0.5rem;
	// 		}
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.48rem;
	//
	// 		strong {
	// 			font-size: 0.54rem;
	// 		}
	// 	}
	//
	// 	p {
	// 		font-size: 0.7rem;
	// 		margin-bottom: 0;
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.box h2 strong {
	// 		font-size: 1.52rem;
	// 	}
	//
	// 	.sl-logo {
	// 		padding-top: 3rem;
	// 	}
	// }
	#scroll_tab .subNav .nav > a,
	header #main-menu .menu-list .menu > ul > li > a {
		font-size: 0.6rem;
	}
}
/* Tablet (Ipad) max-width: 768px */
@media only screen and (max-width : $tablet-width) {
	#contact-fr h1,
	#register-fr .re-txt > h2 {
		font-size: 1.5rem;
	}

	#slider-contact .wrap-caption h3 u,
	#slider-home .wrap-caption h3 u {
		padding: 0.2rem;
	}

	#slider-contact .wrap-caption h3,
	#slider-home .wrap-caption h3 {
		line-height: 1.7rem;
	}
	// **
	.leadership_benefit h3 {
		font-size: 1.3rem;
	}

	.model_construction .left-img {
		padding: 0 7rem;
	}

	#carouselBanner .carousel-indicators > li,
	#carouselBanner .carousel-indicators > li.active {
		width: 0.5rem;
		height: 0.5rem;
	}
	//** SOCIAL BAR
	header #main-menu .menu-list .menu > ul > li > a {
		font-size: 10px;
		padding: 0;
	}
	//** MENU
	.block_slogan_small {
		font-size: 1rem;

		br.rwd-line {
			display: none;
		}
	}
	//** SLOGAN
	.dynamic_banner .sl_logo {
		padding: 2rem 18rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 9.8rem;
		padding-top: 1.9rem;
	}

	.text_banner_slides {
		font-size: 0.8rem;
	}

	.dynamic_banner .box {
		h2 {
			// font-size: 1.25rem;
			font-size: clamp(1.2rem,calc(100% + #{$dev-width-size}),1.25rem)!important;
			color: #439bcf;
			line-height: 1;

			span.ql-size-large {
				// font-size: 1.4rem;
				// font-size: 1.37rem;
				font-size: clamp(1.3rem,calc(100% + #{$dev-width-size}),1.37rem)!important;
				margin-left: 0.1rem;
			}

			sup {
				font-size: 0.6rem;
			}
		}

		h4 {
			font-size: 1rem;
		}

		h5 {
			font-size: 0.39rem;
			padding: 0.2rem 0;

			strong {
				font-size: 0.434rem;
				color: #439bcf;
			}
		}

		h6,
		p {
			font-size: 0.6rem;
		}

		.btn_flat {
			min-width: 1rem;
			font-size: 0.6rem;
			padding: 0.2rem 1rem;
		}
	}

	html:lang(en) .dynamic_banner {
		.sl-logo {
			padding-top: 2rem;
		}

		.box h2 {
			// font-size: 1.24rem;
			font-size: clamp(1.2rem,calc(100% + #{$dev-width-size}),1.25rem)!important;
			margin-left: 0;

			span.ql-size-large {
				// font-size: 1.24rem;
				font-size: clamp(1.2rem,calc(100% + #{$dev-width-size}),1.25rem)!important;
				margin-left: 0;
			}

			sup {
				top: -0.6rem;
			}
		}
	}
	// ** END DYNAMIC BANNER SLIDER
	.leadership-method .wrap {
		h3 {
			// font-size: 2.5rem;
			font-size: clamp(2.4rem,calc(100% + #{$dev-width-size}),2.5rem)!important;

			span.ql-size-large {
				// font-size: 2.8rem;
			font-size: clamp(2.5rem,calc(100% + #{$dev-width-size}),2.8rem)!important;
				margin-left: 0.5rem;
			}

			strong {
				font-size: 2.6rem;
			}
		}

		h4 {
			font-size: 0.78rem;

			strong {
				font-size: 0.86rem;
			}
		}

		p {
			font-size: 0.8rem;
		}
	}

	html:lang(en) .leadership-method .wrap h3 {
		// font-size: 2.45rem;
		font-size: clamp(2.4rem,calc(100% + #{$dev-width-size}),2.45rem)!important;
		margin-left: 0.5rem;

		span.ql-size-large {
			// font-size: 2.45rem;
			font-size: clamp(2.4rem,calc(100% + #{$dev-width-size}),2.45rem)!important;
			margin-left: 0.5rem;
		}
	}
	// ** END LEADERSHIP METHOD
	.leadership_benefit h3 {
		font-size: 1.4rem;
	}
	// ** END LEADERSHIP BENEFIT
	.plan .wrap {
		width: 100%;

		h3 {
			font-size: 1.4rem;
		}
	}
	// ** END PLAN
	#all_ready {
		.ar-title {
			font-size: 1.4rem;
		}

		.ar-content {
			padding: 0;

			ul {
				padding-inline-start: 20px;
			}
		}
	}
	// ** END ALL READY
	footer .top-ft .ft-title > h4 {
		font-size: 1rem;

		span {
			display: unset !important;
		}
	}
	// ** FOOTER
	.btn_flat {
		min-width: 9rem;
		font-size: 0.75rem;
		padding: 0.2rem 0.5rem;
	}
	// ** END BUTTON
	#knowledge-tab, #scroll_tab {
        top: 4.7rem;
    }
	#scroll_tab {
		.subNav .nav > a {
			font-size: 0.5rem;
		}
	}
	#knowledge-tab .taskbar-nt .wrap .switch-tab>a {
        font-size: 0.6rem;
    }
	// ** END SCROLL TAB

	#knowledge-list .subject-tl>h3 {
        font-size: 1.2rem;
    }
    #knowledge-list .article-special .box>h4 {
        font-size: 1rem;
    }
    #knowledge-list .article-item .box>h5 {
        font-size: 0.6rem;
        min-height: 6rem;
    }
    #knowledge-list .article-special .box .article-more>a, #knowledge-list .article-item .box .article-more>a {
        font-size: 0.6rem;
    }
    // ** End Kho Hoc Lieu

    #event-list .wrap>h4 {
        font-size: 1rem;
    }
    .button-component .button-inner .button-second-inner .button-text {
        font-size: 0.6rem;
    }
    .button-component .button-inner .button-second-inner .icon-component {
        width: 1.2rem;
        height: 1.2rem;
    }
    .view>img {
        min-height: 25rem;
    }
    body {
        font-size: 13px;
    }
    // ** End

	.model_construction {
		.left-img {
			padding-top: 1.5rem;
		}

		.right-tile {
			h2 {
				font-size: 1.4rem;
				line-height: 1.4;
				margin-bottom: 0;
			}

			h3 {
				font-size: 1.4rem;
				line-height: 2rem;
			}
		}
	}
	// ** END MODEL CONSTRUCTION
	#slider-contact .wrap-caption,
	#slider-home .wrap-caption {
		margin-right: 0;
		margin-bottom: 0;
		padding: 0 3rem;

		h3 {
			font-size: 1rem;
		}
	}
	#slider-intro .wrap-caption h3 {
        font-size: 1rem;
    }
    #slider-intro .wrap-caption .logo-img>img {
        max-width: 30%;
    }
    #introduce .intro-title h2 {
        font-size: 1.7rem;
    }
	// ** END CAPTION SLIDER
	.schedule_open .register .border-tops {
		border-top: 1px solid #dee2e6!important;
	}

	.event-table {
		table {
			// border: 0;
			thead {
				display: none;
			}

			th {
				width: 100vw;
			}

			tr {
				// margin-bottom: 10px;
				display: block;
				// border-bottom: 2px solid #ddd;
			}

			td {
				display: block;
				// text-align: right;
				// border-bottom: 1px solid #ccc;
			}

			td:before {
				content: attr(data-label);
				float: left;
				/* text-transform: uppercase; */
				font-weight: bold;
			}
		}
	}

	.border-right {
		border-right: unset !important;
	}
}
@media only screen and (max-width : 750px) {
	.dynamic_banner .box .btn_flat {
		font-size: 0.5rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 9.4rem;
		padding-top: 2.1rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 1.24rem;
	// 		strong {
	// 			font-size: 1.35rem;
	// 		}
	// 	}
	// 	h5 {
	// 		font-size: 0.38rem;
	// 		strong {
	// 		 font-size: 0.42rem;
	// 	 }
	// 	}
	// 	h6 {
	// 		font-size: 0.5rem;
	// 		margin-bottom: 0;
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 1rem;
	// }
}
@media only screen and (max-width : 610px) {
	.dynamic_banner .box .btn_flat {
		font-size: 0.4rem;
	}

	.dynamic_banner .sl-logo {
		padding-left: 7.3rem;
		padding-top: 1.3rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 0.95rem;
	//
	// 		strong {
	// 			font-size: 1.06rem;
	// 		}
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.3rem;
	//
	// 		strong {
	// 			font-size: 0.33rem;
	// 		}
	// 	}
	//
	// 	h6,
	// 	p {
	// 		font-size: 0.4rem;
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.box h2 {
	// 		font-size: 0.9rem;
	//
	// 		strong {
	// 			font-size: 0.9rem;
	// 		}
	//
	// 		sup {
	// 			top: -1.2rem;
	// 		}
	// 	}
	// }
}
/* Tablet (Ipad) max-width: 576px */
@media only screen and (max-width : 576px) {
	#knowledge-list .article-item .box>h5 {
    	font-size: 1rem;
    }
    .intro-box .logo-img img {
        max-width: 50%;
    }
    .view .mask .slii-content {
        font-size: 0.75rem;
    }
    #slider-intro .wrap-caption .logo-img>img {
        max-width: 40%;
    }
    #introduce .intro-title h2 {
        font-size: 1.5rem;
    }
    header #main-menu .navbar .kbv {
        min-width: 10rem;
    }
    .item-detail .display-box .resources-form #thanks h4 {
        font-size: 1rem;
    }
    // ** End new css

	.msgSuccess > h2,
	.msgSuccess h3,
	.msgSuccess h4,
	.msgSuccess h5 {
		font-size: 1rem;
	}

	.item-detail .display-box .resources-form {
		height: 500px;
		display: unset;
	}

	.item-detail .display-box .resources-form .unblock-form {
		position: relative;
	}
	// **
	.banner-default {
		height: auto !important;
	}

	#knowledge-tab {
		top: 4.5rem;

		.taskbar-nt .wrap .switch-tab > a {
			font-size: 0.6rem;
		}
	}

	.img_top {
		height: 28rem;
		-o-object-fit: cover;
		object-fit: cover;
	}

	header {
		#main-menu {
			background-color: #ffffff;

			.navbar {
				.pace {
					min-width: 6rem;
					margin-right: 1rem;
				}

				.kbv {
					min-width: 8rem;
					margin-left: 0;
				}
			}

			.logo {
				position: unset !important;
				align-items: center;
				display: flex;
				justify-content: center;
				background-color: #ffffff;
			}

			.menu-list {
				background-color: #333F48;

				.menu {
					.nav {
						display: block;
					}

					> ul {
						> li {
							padding: 0.5rem 0;

							> a {
								color: #ffffff;
							}
						}
					}

					.social-mobile {
						.googlesearch {
							.search {
								svg {
									margin-bottom: 0.3rem;
								}
							}

							.space {
								border-right: solid 1px #ffffff;
								margin-left: 1rem;
								margin-right: 1rem;
							}
						}

						.network-social {
							padding-right: 0.7rem;

							.flag-2 {
								color: #ffffff;
							}
						}

						.language {
							> a {
								color: #ffffff;
								text-transform: uppercase;
							}

							.space {
								border-left: 1px solid #ffffff;
								margin-right: 1rem;
								content: '';
							}

							> a.active {
								color: #97d700;
							}
						}
					}
				}
			}
		}
	}
	// **  END HEADER
	.dynamic_banner .slii {
		display: none;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 12rem;
	}

	.dynamic_banner .sl-logo {
		padding: 2rem 12rem 0;
		max-height: 0;
	}

	.dynamic_banner .sl_content {
		display: unset;
	}
	// .dynamic_banner .box {
	// 	align-self: unset;
	//
	// 	h6,
	// 	p {
	// 		font-size: 0.6rem;
	// 	}
	//
	// 	.btn_flat {
	// 		font-size: 0.6rem;
	// 	}
	//
	// 	h2 {
	// 		//font-size: 2rem;
	// 		font-size: 1.8rem;
	// 		color: #439bcf;
	// 		line-height: 1;
	//
	// 		strong {
	// 			//font-size: 2rem;
	// 			font-size: 2rem;
	// 			position: relative;
	// 			left: 0.4rem;
	// 		}
	//
	// 		sup {
	// 			font-size: 0.8rem;
	// 		}
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.56rem;
	// 		padding: 0.2rem 0;
	//
	// 		strong {
	// 			font-size: 0.62rem;
	// 			color: #439bcf;
	// 		}
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner {
	// 	.sl-logo {
	// 		padding-top: 3rem;
	// 	}
	//
	// 	.box h2 {
	// 		font-size: 1.8rem;
	// 	}
	// }
	// ** END DYNAMIC BANNER SLIDER
	header #main-menu .menu-list .menu > ul > li > a {
		font-size: 1rem;
	}
	// ** END MENU
	.model_construction {
		.left-img {
			text-align: center;
			padding: 0 7rem;
		}
	}
	// ** END MODEL CONSTRUCTION
}
