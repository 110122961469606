/************** STYLE GOLBAL CSS **************/
/**TAG ORIGIN**/
body {
  font-family: Open Sans, sans-serif;
}

h2, .h2 {
  font-family: 'Barlow', 'Montserrat', sans-serif;
  font-weight: 300;
}

h3, .h3, h5, .h5 {
  font-family: 'Barlow', 'Montserrat', sans-serif;
}

.checkbox-lg .form-check-input {
  scale: 1.4;
  margin-right: 0.7rem;
}

.checkbox-xl .form-check-input {
  scale: 1.7;
  margin-right: 0.8rem;
}

.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #008065;
  border-color: #008065;
}

.text_brand {
  color: #003146;
}

//u {
//  text-decoration: none;
//  background-color: #333f48;
//  color: #ffffff;
//  padding: 0 15px;
//}
.content {
  u {
    text-decoration: none;
    //  background-color: #333f48;
    //  color: #ffffff;
    //  padding: 0 15px;
  }

}

.btn-info {
  min-width: 10rem;
}

.font-barlow {
  font-family: 'Barlow', 'Montserrat', sans-serif;
}

.btn_large {
  font-size: 1.2rem;
}

.btn-brochure {
  background-color: #fff;
  border: 1px solid #a63c00;
  color: #a63c00;
  min-width: 12rem;
}

.slogan-first .btn, #carouselBanner .btn {
  min-width: 10rem;
  //margin: 0 20px!important;

}

.ql-align-justify {
  text-align: justify !important;
}

.sliderSLII .content h3 strong, .sliderSLII .content h5 strong, .sliderSLII .content h6 strong, .model h2 strong {
  color: #008065;
  //-webkit-text-stroke-width: thin;
  //font-weight: 600;
  //filter: drop-shadow(rgba(0, 0, 0, 0.04) 0 10 8px) drop-shadow(rgba(0, 0, 0, 0.1) 0 4px 3px);
}

.sliderSLII .content h3 strong {
  font-weight: 500;
}

.sliderSLII .content h5 strong {
  font-weight: 600;
}

/**VARIABLES**/
.green-bg-color {
  background-color: #97d700;
}

.grid {
  display: grid;
}

.btn-global {
  border-color: #97d700;
  color: #212529;
  text-transform: uppercase;
  border-radius: unset;
}

.btn-global:hover, .btn-global:focus {
  color: #ffffff;
  background-color: #7cb100;
  border-color: #73a400;
  box-shadow: 0 0 0 0.2rem rgb(133 188 6 / 50%);
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

/*SLIDER KBV RENEW*/
.sliderKBV {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.sliderKBV .content {
  display: grid;

  h3 {
    color: #003146;
  }
}

/*SLIDER SLII RENEW*/
.sliderSLII .content h3 {
  color: #005D7D;
}

.sliderSLII {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.sliderSLII .content h5 {
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  display: inline-block;
}

.sliderSLII .content h3 sup {
  font-size: 55%;
}

html:lang(en) .sliderSLII .content h3 span {
  letter-spacing: 0;
}

html:lang(vi) .sliderSLII .content h3 sup {
  //right: .2em;
}

html:lang(en) .sliderSLII .content h3 sup {
  top: -.8em;
}


/*ADMISSION*/

.schedule_open {
  h2 {
    font-weight: 500;
    font-size: 48px;
  }
}

.schedule_open .course-vat {
  padding-top: 5px;
  padding-bottom: 5px;
}

.schedule_open .course-vat P {
  margin: 0;
}

/*MODEL PROVEN*/
.model h2 {
  font-weight: 600;
}

.model h2 strong {
  color: #005D7D;
  font-weight: 600;
}

.model h5 {
  color: #005D7D;
  border-left: 5px solid #005D7D;
  padding-left: 5px;
}

/*POWERING INSPIRED*/
.powering {
  position: relative;

  ._img_model {
    border: #C1D8E5;
    border-radius: 15px;
    box-shadow: 7px 6px 10px 0px #07233E0D;
    background-color: #ffffff;
    padding: 2rem 6rem;
  }
}

.powering .out-img {
  opacity: 0.2;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  overflow: hidden;

  width: 100%;
  height: 100%;
}

.powering .out-img img {
  //max-width: 100% !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.powering .in-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*LEADERSHIP*/
.leadership p {
  //margin: 0;
}

/*SLOGANS*/
.slogan .wrap {
  text-align: justify;
}

.slogan-first .wrap {
  padding-bottom: 10px;
}

.slogan-first .wrap p {
  margin: 0;
}

/*PLANNING*/
.planning {
  color: #ffffff;

  h2, ul li {
    color: #ffffff;
    text-align: justify;
  }

  h2 u {
    background-color: transparent;
  }

  h3 {
    color: #ffffff;
  }

  h3 strong {
    font-weight: 500;
    font-size: 36px;
  }

  img {
    width: 100%;
    height: 100%;
    //object-fit: cover;
    //opacity: 0.9;
    //filter: blur(2px);
    //-webkit-filter: blur(2px);
  }

  .content {
    width: 100%;
    height: 100%;
  }
}

.clearimg img {
  filter: unset;
  -webkit-filter: unset;
  opacity: 1;
}


/*ALL READY*/
.ready h2 {
  color: #ffffff;
}

/*SLII EXPERIENCE*/
.experience .content {
  display: grid;
}

.experience .content h3 {
  color: #005D7D;
  line-height: 1;
}

.experience .content h3 strong {
  //-webkit-text-stroke-width: thin;
  font-weight: 500;
  color: #008065;
}

.experience .content h5 {
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 10px 0;
  margin: 15px 0;
  display: inline-block;
}

.experience .content h5 strong {
  color: #005D7D;
  //-webkit-text-stroke-width: thin;
  font-weight: normal;
  font-size: 0.9em;
}

.experience .content h5 em {
  font-size: 15px;
  letter-spacing: 0.1px;
}

.experience .content p {
  margin: 0;
}

.experience .content h3 sup {
  font-size: 55%;
  //right: 0.2em;
}

html:lang(en) .experience .content h3 sup {
  top: -.8em;
}

.mg-top-30 {
  margin-top: 30px !important;
}

/* End main content */
/************** MOBILE BREAKPOINT **************/
@media screen and (max-width: 768px) {
  body {
    font-size: 15px;
  }
  h2, .h2 {
    font-size: 22px;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    margin-left: .3rem;
    margin-right: .3rem;
  }
  .carousel-indicators {
    bottom: -10px;
  }
  /* End tag origin html */
  .btn-global {
    margin-top: 10px;
    padding: 5px 0;
    font-size: 14px;
    min-width: 200px;
  }
  .object-fit-unset {
    -o-object-fit: unset;
    object-fit: unset;
  }
  .object-fit-cover {
    -o-object-fit: cover;
    object-fit: cover;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 24px;
    line-height: 1.8;
    color: #003146;
  }
  .sliderKBV .content h3 u {
    display: table;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sliderKBV .content h3 br {
    display: none;
  }

  .leadership {
    h4 {
      font-size: 1.5rem;
    }
  }
  /*KBV Introduce*/
  .introKBV {
    h2 {
      font-size: 30px;
    }
  }


  /*SLIDER SLII RENEW*/
  .sliderBG {
    height: 33rem;
  }
  .sliderSLII .slider-img {
    flex: 0 0 50%;
    max-width: 40%;
    position: relative;
    top: 40px;
  }
  .sliderSLII .content h3 {
    font-size: 40px;
    line-height: 1;
  }
  .sliderSLII .content h3 span {
    letter-spacing: 3px;
    padding-left: 30px;
  }
  .sliderSLII .content h5 {
    font-size: 14px;
    padding: 10px 0;

    br {
      display: block !important;
    }
  }
  .sliderSLII .content h5 em {
    font-size: 12.4px;
  }
  /*POWERING INSPIRED*/
  .powering .in-img {
    width: 27%;
  }
  /*MODEL PROVEN*/
  .model h2 {
    font-size: 20px;
    line-height: 1.4;
  }
  .model h5 {
    font-size: 14px;
  }
  .model img {
    max-width: 50%;
  }
  /*ADMISSION*/
  .schedule_open .course-vat P {
    font-size: 15px;
  }
  html:lang(en) .schedule_open .course-vat P {
    font-size: 12px;
  }
  /*LEADERSHIP*/
  .increase-pd .box {
    padding: 50px 0;
  }
  /*SLOGANS*/
  .slogan-first .wrap p .brk-line {
    display: block;
    padding-top: 5px;
    content: "";
    height: 0;
  }
  .slogan-first .wrap p {
    display: unset;
    padding-right: 5px;
  }
  /*SLII Experience*/
  .experience .content h3 {
    //font-size: 40px;
  }
  .experience .content h3 span {
    //letter-spacing: 3px;
    //padding-left: 30px;
  }
  .experience .content h5 {
    font-size: 14px;
  }
  .experience .content h5 em {
    font-size: 12.4px;
  }
  html:lang(en) .experience .content h3 {
    font-size: 49px;
  }
  html:lang(en) .experience .content h3 span {
    //letter-spacing: 0;
  }
  html:lang(vi) .experience .content h3 sup {
    left: -2px;
  }

  .powering {
    ._img_model {
      padding: 1.5rem;
    }
  }
  /* End main content */
}

@media screen and (max-width: 576px) {
  body {
    font-size: 15px;
  }
  h2, .h2 {
    font-size: 18px;
  }
  /* End tag origin html */
  header #main-menu .menu-list {
    /*display: none;*/
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 19px;
  }
  /*SLIDER SLII RENEW*/
  .sliderBG {
    height: 31rem;
  }
  .sliderSLII .slider-img {
    max-width: 50%;
  }
  .sliderSLII .content h3 {
    font-size: 35px;
  }
  .sliderSLII .content h3 span {
    letter-spacing: 2.2px;
    padding-left: 20px;
  }
  .sliderSLII .content h5 {
    font-size: 12px;
  }
  .sliderSLII .content h5 em {
    font-size: 10.8px;
  }
  .sliderSLII .content h6 {
    font-size: 14px;
  }
  /*SLII Introduce*/
  .introSLII .content p {
    text-align: justify !important;
  }
  .introSLII .content p br {
    display: none;
  }
  /*POWERING INSPIRED*/
  .powering .out-img img {
    object-fit: cover;
    object-position: center;
    height: 500px;
  }
  .powering .in-img {
    width: 80%;
  }
  /*MODEL PROVEN*/
  .model h2 {
    font-size: 19px;
  }
  .model img {
    max-width: 50%;
  }
  .model h5 {
    font-size: 14px;
  }
  /*SLOGANS*/
  .slogan-first .wrap p .brk-line {
    display: block;
    padding-top: 5px;
    content: "";
    height: 0;
  }
  .slogan-first .wrap p {
    display: unset;
    padding-right: 5px;
  }
  /*LEADERSHIP*/
  .leadership .icon img {
    max-width: 60%;
  }
  /*SLII Experience*/
  .experience .content h3 {
    font-size: 34px;
  }
  .experience .content h3 span {
    //letter-spacing: 2px;
    //padding-left: 25px;
  }
  .experience .content h5 {
    font-size: 12px;
    line-height: 1.4;
  }
  .experience .content h5 em {
    font-size: 10.6px;
  }
  .experience .content p {
    font-size: 14px;
  }
  html:lang(en) .experience .content h3 {
    font-size: 31px;
  }
  html:lang(en) .experience .content h3 span {
    //letter-spacing: 2.3px;
    //padding-left: 25px;
  }
  html:lang(vi) .experience .content h3 sup {
    left: -2px;
  }
  /* End main content */
}

@media screen and (max-width: 425px) {
  h2, .h2 {
    font-size: 16px;
  }
  .schedule_open .content table tbody tr td, .schedule_open .content table tbody tr th {
    display: unset !important;
  }
  .btn-global {
    min-width: 165px;
    font-size: 12px;
  }
  /*KBV Introduce*/
  .introKBV h2 {
    font-size: 24px;

  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 18px;
  }
  /*SLIDER SLII RENEW*/
  .sliderBG {
    height: 27.5rem;
  }
  .sliderSLII .content h3 {
    font-size: 32px;
  }
  .sliderSLII .content h5 {
    font-size: 11px;
  }
  .sliderSLII .content h5 em {
    font-size: 9.8px;
  }
  .sliderSLII .content h6 {
    font-size: 12px;
  }
  /*MODEL PROVEN*/
  .model h2 {
    font-size: 17px;
  }
  .model h5 {
    font-size: 13px;
  }
  /*SLII Experience*/
  .experience .content h3 {
    font-size: 30px;
  }
  .experience .content h3 span {
    //letter-spacing: 2px;
    //padding-left: 20px;
  }
  .experience .content h5 {
    font-size: 10.5px;
    margin-top: 0;
  }
  .experience .content h5 em {
    font-size: 9.3px;
  }
  .experience .content h5 .rwd-line {
    display: block;
  }
  .experience .content p {
    font-size: 12px;
  }
  html:lang(en) .experience .content h3 {
    font-size: 26.5px;
  }
  html:lang(en) .experience .content h3 span {
    //padding-left: 20px;
  }
  /* End main content */
}

@media screen and (max-width: 375px) {
  body {
    font-size: 14px;
  }
  h2, .h2 {
    font-size: 14px;
  }
  .btn-global {
    font-size: 11px;
    min-width: 150px;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    margin-left: .25rem;
    margin-right: .25rem;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 16px;
  }
  /*SLIDER SLII RENEW*/
  .sliderBG {
    height: 25.5rem;
  }
  .sliderSLII .content h3 {
    //font-size: 29px;
    font-size: 18px;
  }
  .sliderSLII .content h5 {
    font-size: 10px;
  }
  .sliderSLII .content h5 em {
    font-size: 9px;
  }
  .sliderSLII .content h3 span {
    letter-spacing: 1.8px;
    padding-left: 20px;
  }
  /*POWERING INSPIRED*/
  .powering .out-img img {
    height: 400px;
  }
  /*MODEL PROVEN*/
  .model h2 {
    font-size: 15px;
  }
  .model h5 {
    font-size: 12px;
  }
  /*ADMISSION*/
  .schedule_open .course-vat p {
    font-size: 14px;
  }
  html:lang(en) .schedule_open .course-vat p {
    font-size: 10px;
  }
  /*SLII Experience*/
  .experience .content h3 {
    //font-size: 27px;
  }
  .experience .content h3 span {
    //letter-spacing: 1.6px;
    //padding-left: 18px;
  }
  .experience .content h5 {
    font-size: 9.3px;
    margin: 0 0 5px 0;
  }
  .experience .content h5 em {
    font-size: 8.2px;
  }
  .experience .content p {
    font-size: 10px;
  }
  html:lang(en) .experience .content h3 {
    font-size: 20px;
  }
  html:lang(en) .experience .content h3 span {
    //letter-spacing: 4px;
  }
  html:lang(en) .experience .content p {
    font-size: 9px;
  }
  /* End tag origin html */
}

@media screen and (max-width: 320px) {
  body {
    font-size: 13px;
  }
  h2, .h2 {
    font-size: 13px;
  }
  .btn-global {
    font-size: 11px;
    min-width: 130px;
  }
  /*ADMISSION*/
  .schedule_open .course-vat p {
    font-size: 12px;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 13px;
  }
  /*SLIDER SLII RENEW*/
  .sliderBG {
    height: 23.5rem;
  }
  .sliderSLII .content h3 {
    font-size: 15px;
    //font-size: 25px;
  }
  .sliderSLII .content h5 {
    font-size: 8.8px;
  }
  .sliderSLII .content h5 em {
    font-size: 7.9px;
  }
  /*POWERING INSPIRED*/
  .powering .out-img img {
    height: 340px;
  }
  /*MODEL PROVEN*/
  .model h5 {
    font-size: 11px;
  }
  /*SLII Experience*/
  .experience .content h3 {
    font-size: 22px;
  }
  .experience .content h5 {
    font-size: 7.6px;
  }
  .experience .content h5 em {
    font-size: 6.6px;
  }
  .experience .content p {
    font-size: 9px;
  }
  html:lang(en) .experience .content h3 {
    font-size: 15.5px;
  }
  html:lang(en) .experience .content h3 span {
    //padding-left: 16px;
  }
  html:lang(en) .experience .content p {
    font-size: 7.5px;
  }
  /* End tag origin html */
}

/************** DESKTOP BREAKPOINT **************/
@media screen and (min-width: 768px) {
  /*GOBAL CSS*/
  body {
    font-size: 13px;
  }
  h2, .h2 {
    font-size: 24px;
  }
  .btn-global {
    margin-top: 10px;
    padding: 3px 0;
    font-size: 10px;
    min-width: 115px;
  }
  .carousel-indicators {
    bottom: -18px;
    left: 25px;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    margin-left: .3rem;
    margin-right: .3rem;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 18px;
    line-height: 1.5;
  }
  .sliderKBV .content h3 br {
    display: unset;
  }
  .sliderKBV .content h3 u {
    display: unset;
    margin-left: unset;
    margin-right: unset;
  }
  /*SLIDER SLII RENEW*/
  .sliderBG {
    height: unset;
    -o-object-fit: unset;
    object-fit: unset;
  }
  .sliderSLII .slider-img {
    flex: unset;
    max-width: 100%;
    padding-top: 9%;
    padding-left: 44%;
    top: unset;
  }
  .sliderSLII .content h3 {
    font-size: 25px;
    white-space: nowrap;
    overflow: initial;
    text-overflow: ellipsis;
    line-height: 0.8;
  }
  .sliderSLII .content h3 span {
    padding-left: 0;
    letter-spacing: 1.5px;
    //left: 9px;
    position: relative;
  }
  .sliderSLII .content h5 {
    font-size: 8.5px;
    margin-top: 2px;
    margin-bottom: 5px;
    padding: 5px 0;

    .rwd-line {
      display: block;
    }
  }
  .sliderSLII .content h5 em {
    font-size: 7.6px;
  }
  .sliderSLII .content h6 {
    font-size: 12px;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 12%;
  }

  .leadership {
    h4 {
      font-size: 1.5rem;
    }
  }

  /*MODEL PROVEN*/
  .model h2 {
    font-size: 19px;
  }
  .model img {
    max-width: 100%;
  }
  /*SLOGANS*/
  .slogan-first .wrap p {
    display: block;
    padding-right: unset;
    font-size: 10px;
  }
  .slogan-first .wrap p .brk-line {
    display: unset;
    padding-top: unset;
    content: unset;
    height: unset;
  }
  /*SLII EXPERIENCE*/
  .experience .content h3 {
    //font-size: 50px;
  }
  .experience .content h3 span {
    //letter-spacing: 3px;
    //padding-left: 30px;
  }
  .experience .content h5 {
    font-size: 17px;
  }
  .experience .content h5 em {
    font-size: 15px;
  }
  html:lang(en) .experience .content h3 {
    font-size: 49px;
  }
}

@media screen and (min-width: 992px) {
  h2, .h2 {
    font-size: 30px;
  }
  .btn-global {
    font-size: 12px;
    min-width: 155px;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    width: .6rem;
    height: .6rem;
  }
  /*SLIDER SLII RENEW*/
  .sliderSLII .slider-img {
    padding-top: 11.5%;
    padding-left: 46%;
  }
  .sliderSLII .content h3 {
    font-size: 31px;
    line-height: 0.9;
  }
  .sliderSLII .content h3 span {
    //letter-spacing: 1.9px;
    //left: 11px;
  }
  .sliderSLII .content h5 {
    font-size: 10.6px;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 10px 0;
  }
  .sliderSLII .content h5 em {
    font-size: 9.5px;
  }
  .sliderSLII .content h6 {
    font-size: 12px;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 15%;
  }
  /*MODEL PROVEN*/
  .model h2 {
    font-size: 27px;
  }
  /*SLII EXPERIENCE*/
  html:lang(en) .experience .content h3 {
    font-size: 44px;
  }
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 14px;
  }
  .carousel-indicators {
    bottom: -5px;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    margin-left: .4rem;
    margin-right: .4rem;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    width: .7rem;
    height: .7rem;
  }
  /*SLIDER SLII RENEW*/
  .sliderSLII .content h3 {
    //font-size: 32px;
    font-size: 20px;
  }
  .sliderSLII .content h5 {
    font-size: 11px;
  }
  .sliderSLII .content h5 em {
    font-size: 9.8px;
  }
  .sliderSLII .content h6 {
    font-size: 14px;
  }
  /*SLOGANS*/
  .slogan-first .wrap p {
    font-size: 12px;
  }
  /*MODEL PROVEN*/
  .model h5 {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  h2, .h2 {
    font-size: 34px;
  }
  .btn-global {
    font-size: 14px;
    min-width: 190px;
  }
  .carousel-indicators {
    left: 35px;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 28px;
  }
  /*SLIDER SLII RENEW*/
  .sliderSLII .content h3 {
    font-size: 38px;
    line-height: 1;
  }
  .sliderSLII .content h3 span {
    //letter-spacing: 2.2px;
    //left: 13px;
  }
  .sliderSLII .content h5 {
    font-size: 13px;
    margin-top: 4px;
    margin-bottom: 10px;
  }
  .sliderSLII .content h5 em {
    font-size: 11.7px;
  }
  .sliderSLII .content h6 {
    font-size: 14px;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 16.5%;
  }
  /*POWERING INSPIRED*/
  .powering .in-img {
    width: 25%;
  }
  /*SLOGANS*/
  .slogan-first .wrap p {
    font-size: 14px;
  }
  /*MODEL PROVEN*/
  .model h2 {
    font-size: 35px;
  }
  .model h5 {
    font-size: 18px;
  }
}

@media screen and (min-width: 1440px) {
  body {
    font-size: 15px;
  }
  .btn-global {
    padding: 7px 0;
  }
  .carousel-indicators {
    bottom: 5px;
  }
  #carouselBanner .carousel-indicators > li, #carouselBanner .carousel-indicators > li.active {
    width: .9rem;
    height: .9rem;
  }
  .btn-global {
    font-size: 16px;
    min-width: 235px;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 34px;
  }
  /*SLIDER SLII RENEW*/
  .sliderSLII .slider-img {
    padding-top: 13%;
    padding-left: 48%;
  }
  .sliderSLII .content h3 {
    font-size: 45px;
  }
  .sliderSLII .content h3 span {
    //letter-spacing: 2.5px;
    //left: 16px;
  }
  .sliderSLII .content h5 {
    font-size: 15.5px;
  }
  .sliderSLII .content h5 em {
    font-size: 13.8px;
  }
  .sliderSLII .content h6 {
    font-size: 16px;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 17.5%;
  }
  /*SLII Introduce*/
  .introSLII .wrap {
    max-width: 12%;
  }
  /*SLOGANS*/
  .slogan-first .wrap p {
    font-size: 15px;
  }
}

@media screen and (min-width: 1680px) {
  .carousel-indicators {
    bottom: 5px;
  }
  /*SLIDER SLII RENEW*/
  .sliderSLII .slider-img {
    padding-top: 14%;
    padding-left: 50.5%;
  }
  .sliderSLII .content h3 {
    font-size: 52px;
  }
  .sliderSLII .content h3 span {
    //letter-spacing: 3px;
    //left: 18px;
  }
  .sliderSLII .content h5 {
    font-size: 18px;
  }
  .sliderSLII .content h5 em {
    font-size: 16px;
  }
  .sliderSLII .content h6 {
    font-size: 18px;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 19.5%;
  }
}

@media screen and (min-width: 1860px) {
  body {
    font-size: 17px;
  }
  /*SLIDER KBV RENEW*/
  .sliderKBV .content h3 {
    font-size: 40px;
  }
  /*SLIDER SLII RENEW*/
  .sliderSLII .slider-img {
    padding-top: 15%;
  }
  .sliderSLII .content h3 {
    font-size: 58px;
  }
  .sliderSLII .content h3 span {
    letter-spacing: 3.5px;
  }
  .sliderSLII .content h5 {
    font-size: 20px;
  }
  .sliderSLII .content h5 em {
    font-size: 18px;
  }
  .sliderSLII .content h6 {
    font-size: 20px;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 20.5%;
  }
}

@media screen and (min-width: 1920px) {
  /*SLIDER SLII RENEW*/
  .sliderSLII .slider-img {
    padding-top: 15%;
    padding-left: 51.5%;
  }
  .sliderSLII .content h3 {
    font-size: 54px;
  }
  .sliderSLII .content h3 span {
    letter-spacing: 3.5px;
  }
  .sliderSLII .content h5 strong {
    font-size: 19.5px;
  }
  .sliderSLII .content h5 em {
    font-size: 1.06rem;
  }
  html:lang(en) .sliderSLII .slider-img {
    padding-top: 19.5%;
  }
}

.megamenu {

  border: unset;

  .mw_300 {
    min-width: 300px;
  }

  .dropdown-header {
    color: #008065;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    white-space: normal;
  }

  a.dropdown-item, header.nav-active .menu > ul > li a.dropdown-item {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    padding: 5px 20px;
    font-size: 13px;
    line-height: 18px;
    //text-transform: uppercase;

    color: #333F48;
  }

  a.icon_right:before {
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    fill: #333F48;
    color: #333F48;
    background-image: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.05342 0.946581L0.44275 1.55725L2.88552 4.00002L0.442768 6.44277L1.05344 7.05344L4.10678 4.00009L4.10669 4L4.10676 3.99993L1.05342 0.946581Z' fill='currentColor'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: -11px;
  }

  a.icon_right:hover, a.icon_right:focus {
    transform: translateX(5px);
    transition: all .2s ease-in-out;
    fill: #008065;
    color: #008065;
  }

  a.icon_right:focus:before, a.icon_right:hover:before {
    transform: translateX(5px);
    transition: all .2s ease-in-out;
    fill: #008065;
    color: #008065;
    filter: invert(76%) sepia(10%) saturate(1913%) hue-rotate(173deg) brightness(89%) contrast(85%);
    //filter: invert(75%) sepia(11%) saturate(1362%) hue-rotate(168deg) brightness(88%) contrast(88%);
  }

  .dropdown-item:focus, .dropdown-item:hover {
    background-color: unset;
  }

  a.icon_right:hover {
    color: #008065;
  }

  span:hover:before {
    background: #fff;
    width: 100%;

  }

  .dropdown-item.active, .dropdown-item:active {
    background-color: unset;
  }
}

.breadcrumb-item {
  font-family: 'Barlow', 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;

  color: #333F48;
}

.breadcrumb-item:first-of-type {
  margin-top: -2px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "//";
}

header {
  #main-menu {
    .dropdown:focus > .dropdown-menu, .dropdown:hover > .dropdown-menu {
      display: block;
    }

    .dropdown > .dropdown-toggle:active {
      /*Without this, clicking will make it sticky*/
      pointer-events: none;
    }

    .dropdown-menu {
      position: relative;
      float: none;
    }
  }
}

.sliderSolution {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  h2 {
    //font-family: 'Barlow','Montserrat', sans-serif;
    //font-style: normal;
    //font-weight: 400;
    //font-size: 1.2rem;
    //line-height: 150%;
    ///* or 68px */
    //
    //text-align: center;
    //text-transform: uppercase;

    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 130%;
    /* or 58px */

    text-align: center;
    //text-transform: uppercase;

    color: #005D7D;

    filter: drop-shadow(0px 0px 10px #FFFFFF) drop-shadow(0px 0px 20px #FFFFFF);
  }
}

.slii_powering {

  ._img_content_rounded {
    img[src^="data"] {
      background-color: #fff;
      border: 1px solid #c1d8e5;
      padding: 1rem 2rem;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
      border-radius: 0.5rem;
    }
  }

  ._img_rounded {
    img[src^="data"] {
      background-color: #fff;
      border: 0 solid #c1d8e5;
      box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
      border-radius: 0.5rem;
    }
  }

  h4 {
    font-family: 'Barlow', 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 42px;
    /* or 124% */

    text-align: center;

    color: #003146;
  }

  .w-full {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;

    text-align: justify;
    color: #333F48;

    .box_content {
      h6 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 130%;
        /* or 34px */

        text-align: center;

        color: #003146;
      }

      ul {
        list-style: square;
        list-style-position: inside;
        padding: 1rem 0;
        color: #333F48;

        li {
          padding-bottom: 15px;
        }
      }
    }
  }

  .box_heading {

    font-style: normal;
    //padding: 30px 10px 20px;
    padding: 90px 10px 20px;
    line-height: 30px;
    text-align: center;

    p {
      margin: 3px 0;

      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      text-align: center;
      color: #333F48;
    }

    strong {
      font-weight: 600;
      //font-size: 22px;
      //color: #008065;
    }

    h2 {
      font-family: 'Barlow', 'Montserrat', sans-serif;
      font-weight: 400;
      font-size: 3rem;
      line-height: 100%;
      text-align: center;

      color: #008065;
    }

    h2 span {
      font-weight: 400;
      color: #005D7D;
      font-size: 3.3rem;
    }

    h2 strong {
      font-weight: 500;
      color: #008065;

    }

    h3 {
      border-top: 1px solid rgba(0, 0, 0, .1);
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      display: inline-block;
      font-size: 24px;
      padding: 5px 0;
      margin: 8px 0;
    }

    h3 strong {
      color: #005D7D;
      font-weight: 400;
    }

    h5 {
      font-family: 'Barlow', 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 25.5px;
      line-height: 120%;

      text-align: center;

      color: #333F48;
    }

    h4 .ql-size-large {
      font-weight: 300;
      font-size: 34px;
    }

    .content {
      h6 {
        font-style: normal;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        /* color: #008065; */
        strong {
          color: #008065;
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }

  .image-box {
    border: 1px solid #000000;

    .box_heading {
      padding: 15px 10px 10px;
      min-height: 4rem;

      h5 {

        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        color: #333F48;
      }
    }

    .box_content {
      padding: 0px 20px 11px;
    }
  }

  .container {
    p > img {
      max-width: 100%;
      height: auto;
    }
  }

  .box_container {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(13em, 60em));
    //grid-template-columns: repeat(auto-fit, minmax(13em, 25em));

    justify-content: center;
    justify-items: center;
    align-content: space-evenly;

    .bordered_boxes {
      margin: 10px 15px 0;
      position: relative;
      padding: 0;
      box-sizing: border-box;
      border-width: 1px;
      border-color: #000000;
      border-style: solid;
      width: 100%;

      border-radius: 0.5rem !important;

      .box_heading {
        font-style: normal;
        padding: 0;
        line-height: 30px;
        text-align: center;

        p {
          margin: 3px 0;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 25px;
          text-align: center;
          color: #333F48;

          background-color: #e5eff6;
          padding-left: 1.5rem !important;
          padding-right: 1.5rem !important;
        }

        strong {
          font-weight: 600;
          //font-size: 22px;
          //color: #008065;
        }

        h2 {
          font-weight: 700;
          font-size: 22px;
          line-height: 100%;
          text-align: center;

          color: #55B0E7;
        }

        h3 {
          border-top: 1px solid rgba(0, 0, 0, .1);
          border-bottom: 1px solid rgba(0, 0, 0, .1);
          display: inline-block;

          font-size: 20px;
          padding: 5px 0;
          margin: 8px 0;

        }

        h5 {
          font-family: 'Barlow', 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 25.5px;
          line-height: 120%;

          text-align: center;

          color: #333F48;
        }
      }

      .bordered_box_title:before, .bordered_box_title:after {
        //background: #000000;
      }

      .box_content {
        //font-style: normal;
        //font-size: 25px;
        //line-height: 140%;
        //text-align: center;
        //color: #083f60;

        font-family: 'Open Sans', sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        padding: 30px;
        margin-top: 10px;
        line-height: 150%;
        text-align: justify;
        color: #333F48;
        height: 100%;

        strong {
          font-weight: 700;
        }

        p {
          margin: 0;
        }

        img {
          margin: 0 auto;
          display: flex;
          max-width: 100%;
          height: auto;
        }
      }
    }

    .bordered_box_title {
      margin: 0;
      display: flex;
      position: absolute;
      left: 0;
      width: 100%;
      top: -15px;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      justify-content: space-evenly;

      font-family: 'Open Sans', sans-serif;
      font-style: normal;

      font-weight: 700;
      font-size: 15px;
      line-height: 25px;

      text-align: center;
      //text-transform: uppercase;

      color: #333F48;

      h2 {
        //color: #49A7DF;
        color: #005D7D;
        font-size: 35px;
      }
    }

    .fullwidth_bordered_boxes {
      grid-column: span 2 / span 2;
      margin: 15px 0;
      position: relative;
      box-sizing: border-box;
      border-width: 0px 1px 1px;
      border-color: #4179B1;
      border-style: solid;
      width: 100%;

      border-radius: 30px;
      //padding: 0 25px;

      .bordered_box_title {
        top: -14px;
      }

      .box_content {
        padding: 40px 24px 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 140%;
        text-align: center;
        color: #083F60;

        h2 {
          font-weight: 500;
          font-size: 24px;
          line-height: 140%;
          text-align: center;
          color: #083F60;

          strong {
            color: #55B0E7;
          }
        }
      }

      .bordered_box_title:before, .bordered_box_title:after {
        //background: #49A7DF;
        background: #4179B1;
      }
    }
  }

  .slogan_quote h4 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 130%;
    text-align: center;
    //text-transform: uppercase;

    color: #333F48;
  }

  .box_content {
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 26px;
      //text-align: center;
      color: #333F48;
      margin: 0;
    }

    p > strong.ql-size-large {
      font-weight: 300;
      font-size: 30px;
      line-height: 130%;
      /* or 39px */

      //text-transform: uppercase;
      color: #333F48;
    }
  }

  .bg_vi, .bg_25white {
    background: rgb(255 255 255 / 25%);
  }

  .box_icon {
    max-width: 384px;
    //width: 384px;
  }

  .bordered_box_title:before, .bordered_box_title:after {
    content: ' ';
    height: 1px;
    //background: #000000;
    display: block;
    width: 37%;
    margin-top: 14px;
  }

  .bordered_box_title:before {
    margin-right: 10px;
  }

  .bordered_box_title:after {
    margin-left: 10px;
  }

  ._vanhoadunghop {
    h3 {
      color: #003146;
      font-weight: 500;
      font-size: 2.3rem;
    }
  }

  ._text_content {
    text-align: center;
  }

  ._tongquan_nangluclanhdao {
    .box_content {
      p {
        font-size: 17px;
      }

      ul {
        margin-top: 1rem;
        list-style-position: outside;
        margin-left: 1rem;
      }
    }
  }
}

._box_coaching {
  .box_heading {
    color: #ffffff;
    padding: 6rem 0 0 0 !important;

    h4 {
      color: #ffffff !important;
    }
  }

  .image-box {
    overflow: hidden;
    background-color: #ffffff !important;
    border-width: 0;
    border-radius: 15px !important;

    .box_heading {
      background-color: #ffffff !important;
      padding: 1rem 0 !important;

      h5 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 130%;
        /* or 23px */

        text-align: center;

        color: #003146;
      }
    }
  }
}

.experience {
  .content {
    h3 {
      span {
        letter-spacing: 3px;
      }
    }

    h5 {
      strong {
        font-size: 1.1rem;
      }
    }
  }
}

.lich_khaigiang {
  p {
    margin: 0;
  }

  .title_heading {
    font-size: 34px;
    line-height: 42px;
    text-align: center;

    color: #333F48;
  }
}

.box_courses {
  display: block;

  .b_content {
    color: #fff;
    font-size: 0.7rem;

    .btn_flat {
      min-width: 8rem;
    }
  }

  .b_content {
    height: 100%;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column-start: 10;
    grid-column-end: 20;
    justify-self: center;

    p {
      //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      margin: 0;
    }

    h4 {
      color: #fff;
      font-size: 0.7rem;
      text-shadow: 0 0 black;

      strong {
        font-weight: 600;
        //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .course_item {
    min-height: 210px;
    margin: 0 0 5px 0;
    position: relative;
    display: grid;
    grid-template-columns: repeat(20, 1fr);

    h4 {
      font-family: 'Barlow', 'Montserrat', sans-serif;
      font-size: 0.7rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;

      text-align: center;
      color: #F2F9FF;
    }

    p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 130%;
      text-align: center;
      color: #FFFFFF;
      //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }

    .btn {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      min-width: 10rem;
      margin: 0 auto;
      color: #ffffff;
      border-radius: 50rem !important;
    }
  }
}

footer {
  .social [class*="fab fa-"] {
    background-color: #fff;
    border-radius: 32px;
    color: #343a40;
    display: inline-block;
    height: 32px;
    line-height: 32px;
    margin: auto 3px;
    width: 32px;
    font-size: 15px;
    text-align: center;
  }

  .social [class*="fab fa-"]:hover {
    background-color: #bbb;
  }

  ._border {
    border-top: 2px solid #005D7D;
  }
}

.connect_content {

  .social [class*="fab fa-"] {
    background-color: #E65300;
    border-radius: 43px;
    color: #ffffff;
    display: inline-block;
    height: 43px;
    line-height: 43px;
    margin: auto 3px;
    width: 43px;
    font-size: 16px;
    text-align: center;
  }

  .social [class*="fab fa-"]:hover {
    background-color: #7cb100;
    color: #000000;
  }
}

._tax_form {
  font-family: 'Barlow', 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  /* or 210% */

  color: #003146;
}

.newsletter {
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 140%;
    text-transform: uppercase;
  }

  .form-control {
    font-size: 11px;
    background-color: transparent !important;
  }

  .btn_newsletter {
    background-color: #005D7D;
    color: #ffffff;
    min-width: unset;
  }
}

.event_feature_article {
  .b_content {
    font-weight: 400;
    font-size: 14.5px;
    line-height: 25px;
    color: #333F48;
    text-align: justify;
  }

  .b_title {
    font-weight: 600;
    font-size: 30px;
    line-height: 125%;

    //text-transform: uppercase;

    color: #404E59;
  }

  .b_readmore {

  }

  .heading {
    //text-transform: uppercase;
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 42px;

    color: #003146;

    u {
      background-color: transparent;
      color: #404E59;
      padding: 3px 5px;
    }
  }

  .list_article {
    ._article {
      border-bottom: 1px solid #bbbbbb;

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .b_title {
      font-weight: 600;
      font-size: 20px;
      line-height: 125%;

      text-align: justify;
      //text-transform: uppercase;

      color: #003146;
    }
  }
}

._icon_bg_none {
  ._bg_icon {
    background-color: unset !important;
  }
}

.leadership {
  h4 {
    font-family: 'Barlow', 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 34px;
    line-height: 130%;
    text-align: center;

    color: #003146;
  }

  h2 {
    color: #003146;
  }

  .box .b_content {
    p {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 5px;
      
      strong {
        line-height: 20px;
        font-size: 17px;
        //color: #003146;
        color: #003146;
        //text-transform: uppercase;
      }
    }
  }

  ._icon {
    min-width: 1rem;
    min-height: 1rem;
  }

  ._bg_icon {
    width: 124px;
    height: 124px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    background-color: #008065;
  }

  ._img_icon {
    object-fit: none;
  }
}

._hinhthuchoctap {
  h4, h2 {
    color: #ffffff !important;
    font-size: 2rem !important;

    strong {
      font-weight: 500;
    }
  }

  .box .b_content {
    p {
      strong {
        color: #ffffff !important;
      }
    }
  }
}

.footer_brand {
  font-weight: 600;
}

.ready_connect {
  display: block;

  h4 {
    font-size: 34px;
  }

  .ready_content {
    background-color: #005D7D;
    color: #ffffff;

    h4 {
      color: #ffffff;
    }
  }

  .connect_content {
    border-radius: 1.5rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Barlow', 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 42px;
    text-align: center;

    color: #003146;
    background-color: #ffffff;
  }
}

@include media-breakpoint-up(sm) {


}

@include media-breakpoint-up(md) {
  header {
    #main-menu {
      .dropdown-menu {
        position: absolute;
        float: left;
      }
    }
  }
  .box_courses {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, minmax(240px, 1fr));

    .course_item {
      min-height: 450px;
      margin: 0;
      position: relative;
      display: grid;
      grid-template-columns: repeat(20, 1fr);
    }

    .b_content {
      height: 100%;
      color: #fff;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-column-start: 11;
      grid-column-end: 20;

      p {
        //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        margin: 0;
      }

      h4 {
        color: #fff;
        font-size: 1.5rem;
        text-shadow: 0 0 black;

        strong {
          font-weight: 600;
          //text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .box_courses {
    .b_content {
      color: #fff;
    }

    .b_content, .course_item {
      min-height: 225px;
      font-size: 0.8rem;

      h4 {
        font-size: 0.8rem;
      }
    }
  }

  .sliderSolution h2 {
    font-size: 1.4rem;
  }
}

@include media-breakpoint-up(lg) {
  .ready_connect {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 1.5rem;
  }
  .slii_powering {
    .box_container {
      gap: 2rem 4.5rem;
      //grid-template-columns: repeat(auto-fit, minmax(20em, 32em));
      //grid-template-columns: repeat(auto-fit, minmax(32rem, 1fr));
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

      .bordered_boxes .box_heading p {
        font-size: 19px;
      }
    }
  }
  #noi-dung, #content {
    .box_container {
      grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    }
  }
  .box_courses {
    .b_content {
      color: #fff;
      font-size: 1rem;

      h4 {
        font-size: 1.2rem;
      }
    }

    .course_item {
      min-height: 360px;

      p {
        line-height: 140%;
      }
    }
  }

  .sliderSolution h2 {
    font-size: 1.8rem;
  }
}

@include media-breakpoint-up(xl) {
  .sliderSLII {
    .content {
      h3 {
        //font-size: 45px;
      }

      h5 {
        font-size: 1rem;

        em {
          //font-size: 17px;
        }
      }
    }
  }
  .experience {
    .content {
      h3 {
        font-size: 3rem;

        span {
          letter-spacing: 4px;
          padding: 0;
        }
      }

      h5 {
        strong {
          font-size: 2rem;
        }
      }
    }
  }
  .box_courses {
    .b_content {
      p {
        font-size: 20px;
      }

      h4 {
        font-size: 27px;
        padding-bottom: 20px;

        strong {
          font-size: 36px;
        }
      }

      .btn {
        font-size: 18px;
        min-width: 18rem;
        padding: 10px 0;
      }
    }

    .course_item {
      min-height: 450px;
    }
  }
  .sliderSolution h2 {
    font-size: 2.8rem;
  }

  .slii_powering {
    .bordered_boxes_title {
      font-size: 19px;

      h2 {
        font-size: 2rem;
      }
    }

    .slogan_quote h4 {
      font-size: 25px;
    }

    .box_heading {
      padding: 95px 20px 23px;

      h2 {
        font-size: 3.2rem;
      }

      h3 {
        font-size: 27px;
        padding: 10px 0;
        margin: 15px 0;
        line-height: 32.4px;
      }
    }

  }

}

@media (min-height: 1200px) {
  .box_courses {
    .course_item {
      min-height: 640px;

      .b_content {
        h4 {
          font-size: 27px;
          padding-bottom: 5px;

          strong {
            font-size: 36px;
          }
        }

        p {
          font-size: 20px;
        }

        .btn {
          font-size: 18px;
          line-height: 21px;
          min-width: 18rem;
          padding: 10px 0;
        }
      }
    }
  }

  .sliderSolution h2 {
    font-size: 2.8rem;
  }
}

._dei_block {
  h2 {
    font-size: 2.3rem;
    font-weight: 500;
    color: #003146;
  }

  p {
    font-size: 14px;
    text-align: justify;
  }
}

@include media-breakpoint-down(md) {
  ._dei_block {
    h2 {
      font-size: 1.3rem;
    }

    p {
      font-size: 12px;
      text-align: justify;
    }
  }
}

._kientao {
  h4, p {
    strong {
      font-weight: 500;
    }
  }
}

.side_by_side {
  h2 {
    color: #003146;

    strong {
      font-weight: 500;
    }
  }
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

///===================
.recommend_solutions {
  position: relative;
  padding: 40px 0;


  @include breakpoint(mw) {
    padding: 60px 0;
  }

  .resources-listing-slider__heading {
    color: #003146;

    strong {
      font-weight: 500;
    }
  }
}

.resources-listing-slider__container {
  padding: 0 0 0 $grid-gutter;

  @include breakpoint(mw) {
    padding: 0 95px;
  }
  @include breakpoint($container-size-large) {
    padding: 0;
    padding-left: calc(50vw - #{$container-size} / 2);
  }
}

.resources-listing-slider__item {
  padding: 5px;
  display: flex !important;
  flex-direction: column;

  @include breakpoint(mw) {
    margin-right: 30px;
  }

}

.resources-listing-slider__card-type {
  font-family: $font-secondary;
  text-transform: uppercase;
  letter-spacing: pxtorem(1.75px);
  font-size: pxtorem(14px);
  font-weight: $fontWeight-bold;
  display: inline-block;
  padding: 3px 7px;
  margin-bottom: 15px;
  width: fit-content;

}

.resources-listing-slider__item {
  &:nth-child(1n) {
    .resources-listing-slider__card {
      background-color: $color-primary;
      color: $color-invert;
    }

    .resources-listing-slider__card-type {
      background-color: $color-invert;
      color: $color-primary;
    }

    .resources-listing-slider__card-heading {
      color: $color-invert;
    }
  }

  &:nth-child(2n) {
    .resources-listing-slider__card {
      background-color: $color-secondary;
      color: $color-invert;
    }

    .resources-listing-slider__card-type {
      background-color: $color-invert;
      color: $color-secondary;
    }

    .resources-listing-slider__card-heading {
      color: $color-invert;
    }

  }

  &:nth-child(3n) {
    .resources-listing-slider__card {
      background-color: #F2F2F1;
      color: $color-base;
    }

    .resources-listing-slider__card-type {
      background-color: $color-secondary;
      color: $color-invert;
    }

    .resources-listing-slider__card-heading {
      color: $color-secondary;
    }
  }
}

.resources-listing-slider__card {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 40px;
  box-shadow: 0 2px 8px rgba(51, 51, 51, 0.25);

}

/********************************
** Slider Nav
********************************/
.resources-listing-slider__btn {
  @include resetBtn;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: none;
  z-index: 1;

  justify-content: center;
  background: $color-invert;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2);

  &.slick-disabled {
    display: none !important;
  }

  @include breakpoint(mw) {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: $color-primary;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary;

    svg {
      fill: $color-invert;
    }
  }

  &--prev {
    transform: rotate(180deg);
    left: 10px;

    @include breakpoint($container-size-large) {
      left: calc(50vw - #{$container-size-large} / 2 + 70px);
    }
  }

  &--next {
    right: 10px;
    @include breakpoint($container-size-large) {
      right: 40px;
    }
  }
}

.resources-listing-slider__dots {
  padding: 0 $grid-gutter;

  .slick-dots {
    @include resetList;
    display: flex;
    margin-top: 40px;
  }

  .slick-dots li {
    flex: 1;
    height: 4px;
    display: block;
    background-color: $color-gray;
    font-size: 0;
  }

  .slick-dots li button {
    @include resetBtn;
    width: 100%;
    padding: 0;
    border: 5px solid transparent;
    position: relative;
    top: -3px;
  }

  .slick-dots li.slick-active button {
    border: 5px solid $color-dark-gray;
    border-radius: 5px;
  }
}

.resources-listing-slider {
  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }
}

.privacy_disclaimer {
  font-size: 13px;
}

.grecaptcha-badge {
  visibility: hidden;
}