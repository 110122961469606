//
// Footer
// --------------------------------------------------
footer {
  background-color: #ffffff;
  font-size: 15px;
  padding: 0;

  .top-ft {
    background-color: #003146;
    font-size: 14px;
    font-weight: 400;
    line-height: 25.2px;

    .ft-title {
      > h4 {
        font-family: 'Barlow','Montserrat', sans-serif;
        font-weight: 600;
        font-size: 1.35em;
        line-height: 140%;
        text-transform: uppercase;
        color: #ffffff;
        border-left: .3rem solid #79add3;
        padding-left: 0.5rem;
        // > span {
        // 	padding-left: 1.5%;
        // 	border-left: 0.3rem solid #79ADD3;
        // }
      }
    }

    .address-contact {
      line-height: 180%;
      color: #FFFFFF;
      // .fui-mail {
      // 	padding-right: 0.7%;
      // }
      // .address, .phone {
      // 	> span {
      // 		color: #79ADD3;
      // 	}
      // }
      .flag {
        > i, > span {
          color: #79ADD3;
        }

        color: #ffffff;
      }
    }
  }

  .bottom-ft {
    background-color: #003146;
    font-size: 13px;
    min-height: 40px;

    .divline:before {
      content: "|";
      margin-left: 3px;
    }

    > span {
      line-height: 180%;
      color: #FFFFFF;
    }
  }
}

svg.footer_info {
  fill: #79ADD3
}
