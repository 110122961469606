.bg_partnermap {
    background: #008065;
}
#register-fr {
	.re-txt {
		> h2 {
		  	font-weight: 300;
		  	font-size: 34px;
		  	line-height: 150%;
		  	color: #333F48;
		  	font-family: $font-family-montserrat;
		  	> span {
			    background-color: #333F48;
			    color: #ffffff;
			}
		}
	}
	#register-form {
		.form-inline {
			> label {
				font-size: 0.92em;

			}
			> button {
		     	background-color: #97D700;
		    	padding: 1% 0;
	  		}
	  		> button:hover {
	    		color: #ffffff;
	  		}
		}

  		.custom-control {
		    padding-left: 1rem;
		    display: inline-flex;
		    .custom-control-label {
			    font-family: 'Barlow','Montserrat', sans-serif;
			    font-weight: 600;
			    font-size: 15px;
			    color: #008065;
			    justify-content: center;
			    align-items: center;
			    vertical-align: middle;
			}
			.custom-control-label:before {
		    	right: 0;
		    	left: unset;
		    	display: none;
		  	}
		  	.checkbox-mark {
			    background: #555;
			    margin: 5px 8px;
			    position: relative;
			    border-radius: 5px;
			}
		}
  	}
}
.contact-block {
	.contact-box {
		#contact-form {
			.form-inline {
				//> button {
				//    background-color: #97D700;
				//    padding: 1% 0;
				//}
				//> button:hover {
				//    color: #ffffff;
				//}
			}
		}
		.wrap {
			.contact_info {
			    background-color: #008065;
				color: #ffffff;
			    > h3 {
				    font-weight: bold;
				    font-size: 20px;
				    line-height: 26px;
				    text-transform: uppercase;
				    color: #ffffff;
				    border-left: solid 5px #ffffff;
				}
		  	}
		  	.map {
		  		> iframe {
				    min-height: 350px;
				}
		  	}
		}
	}
}
