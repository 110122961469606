#carouselBanner {
	.carousel-indicators {
		> li {
			width: 15px;
            height: 15px;
            border: solid 1px #000000;
            border-radius: 25px 25px;
            margin: 0.2rem;
		}
		> li.active {
            background-color: #005D7D;
            width: 17px;
            height: 17px;
            border: unset;
        }
	}
}
.p_mb0 p {
    margin-bottom: unset;
}
.title_ktt {
    background: #333F48;
    padding: 0rem 2rem;
    font-size: 50px;
    line-height: 150%;
    font-weight: 300;
    font-family: $font-family-montserrat;
		p {
			margin-bottom: unset;
		}
}
.slider_dynamic_banner{
	top:0;
	width:100%;
	.slii{
		h2{
			font-weight: 600;
			font-size: 2.1rem;
			line-height: 1.1;
			color: #439bcf;
			text-shadow: 0 4px 4px rgba(31,55,71,.2);
			strong {
				font-size: 2.75rem;
			}
		}
		h5 {
	    border-top: 1px solid #79a3bf;
	    border-bottom: 1px solid #79a3bf;
			display: inline-block;
	    padding: .5rem 0;
	    font-style: normal;
	    font-weight: 500;
	    // font-size: 1rem;
	    font-size: 0.96rem;
	    line-height: 1.3;
	    color: #333f48;
			strong {
			    font-weight: 500;
			    font-size: 1.05rem;
			    line-height: 22px;
			    color: #439bcf;
			    // display: block;
			}
		}
        h6 {
            font-size: 17px;
            font-family: $font-family-open-sans;
        }
	}
}
#slider-home, #slider-contact {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrap-caption {
        margin-right: 35%;
        .logo-img {
        	> img {
            	max-width: 35%;
            }
        }
        h3 {
            font-family: 'Barlow','Montserrat', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 184%;
            color: #333F48;
            span {
	            background-color: #333F48;
	            color: #ffffff;
	            padding: 0.5rem 0.5rem;
	        	}
            strong {
	            background-color: #333F48;
	            color: #ffffff;
	            padding: 0.5rem 0.5rem;
							u {
								text-decoration: none;
							}
	        	}
            u {
	            background-color: #333F48;
	            color: #ffffff;
	            padding: 0.5rem 0.5rem;
							text-decoration: none;
	        	}
        }
    }
}

#slider-intro {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-color: rgb(239 239 239 / 74%);
    display: flex;
    align-items: center;
    justify-content: center;
    .wrap-caption {
        margin-top: 5%;
        .logo-img {
            > img {
                max-width: 22%;
            }
        }
        h3 {
            text-transform: uppercase;
            font-family: 'Barlow','Montserrat', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 184%;
            color: #333F48;
            span {
                background-color: #333F48;
                color: #ffffff;
                padding: 0.5rem 0.5rem;
            }
						strong {
					    background-color: #333f48;
					    color: #fff;
					    display: inline-block;
					    padding: 0.2rem 0.5rem;
							u{
								text-decoration: none;
							}
						}
						u{
							background-color: #333f48;
					    color: #fff;
					    display: inline-block;
					    padding: .2rem .5rem;
					    text-decoration: none;
						}
        }
    }
}

#SLII-slider-caption {
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    // padding: 0% 8%;
		// padding: 0 2rem;
    .wrap-box {
    		padding-left: 0rem;
        display: inline-flex;
        .wrap {
	        // max-width: 37rem;
						max-width: 36rem;
						min-width: 35rem;
						padding: 0px 1rem;
	        // padding: 0 2.56%;
	        font-family: 'Barlow','Montserrat', sans-serif;
            .wrap-txt {
                // padding-top: 2%;
								text-align: center;
    	        > h2 {
    		        font-weight: 600;
    		        font-size: 34px;
    		        line-height: 43px;
    		        color: #439BCF;
    		        text-shadow: 0px 4px 4px rgba(31, 55, 71, 0.2);
								strong {
									display: block;
									font-size: 2.75rem;
								}
    		    }
    		    > hr {
                    margin-top: 0.8rem;
                    margin-bottom: 0.8rem;
    		    		// margin-right: 1.5rem;
    		    }
    		    > h5 {
    		        // font-weight: 500;
    		        // font-size: 16.5px;
    		        // line-height: 22px;
    		        // color: #439BCF;
								border-top: 1px solid #79A3BF;
						    border-bottom: 1px solid #79A3BF;
						    padding: 0.5rem 0;
						    font-style: normal;
						    font-weight: 500;
						    font-size: 15.3px;
						    line-height: 24px;
						    color: #333f48;

								strong {
									font-weight: 500;
									font-size: 16.5px;
									line-height: 22px;
									color: #439bcf;
									display: block;
								}
								.rwd-line {
									display: none;
									&::after {
										content: "\00a0 ";
									}
								}
    		    }
    		    > h6 {
    		        font-style: normal;
    		        font-weight: 500;
    		        font-size: 15.3px;
    		        line-height: 22px;
    		        color: #333F48;
						strong{
							//font-size:1.6rem;
                            font-size: 17px;
						}
    		    }

    		    > h4 {
    		        font-style: normal;
    		        font-weight: 600;
    		        font-size: 15.7px;
    		        line-height: 28px;
    		        letter-spacing: -0.01em;
    		        color: #439BCF;
    		    }
            }
	    }
    }
}

#slider-intro-top {

}
.partners-world {
    padding: 3% 0;
    background-color: #E5EFF6;
    .partners-world-title {
        font-family: 'Barlow','Montserrat', sans-serif;
        font-weight: 300;
        font-size: 2em;
        line-height: 42px;
        text-align: center;
        color: #333F48;
    }
    .partners-sub {
        font-size: 1em;
        line-height: 26px;
        text-align: center;
        color: #333F48;
        // padding-left: 35%;
        // padding-right: 35%;
        //padding-bottom: 3%;
    }
    .partners-world-slides {
        // padding: 20px 150px;
        padding: 1rem 2rem;
        .slick-list {
            .slick-track {
                -webkit-box-align: center;
                align-items: center !important;
                display: flex;
                .slick-slide {
                    display: flex;
                    -webkit-box-pack: center !important;
                    justify-content: center !important;
                    > img {
                        display: block;
                    }
                }
            }
        }
    }
}

.text_banner_slides {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    color: #439BCF;
}
.slider_marketing {
    h2 {
        font-weight: 600;
        font-size: 25px;
        line-height: 42px;
        color: #333F48;
    }
    .wrap {
        padding:0rem 17.1rem;
        // padding:0 24.7%;
        font-family: $font-family-montserrat;
        img {
            padding: 5% 25%;
        }
        h3 {
            font-weight: 600;
            font-size: 35px;
            line-height: 1.2;
            color: #439BCF;
            padding-bottom: 0.5rem;
            > strong {
                font-size: 1.29em;
            }
        }
        h4 {
            font-weight: 500;
            font-size: 15.9px;
            color: #333F48;
            margin-bottom: 1rem;
            padding-bottom: 1%;
            border-top: 1px solid rgba(0,0,0,.1);
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding: 1rem 0rem;
            line-height: 2;
            strong{
                font-size: 17.3px;
                color: #008065;
                margin-top: 1rem;
                padding-top: 1%;
            }
        }
        p {
            padding-top: 1%;
            font-size: 15px;
            color: #333F48;
        }
        h5 {
            font-weight: 600;
            font-size: 34.5px;
            color: #008065;
            padding-top: 1%;
        }
        h5, h6{
            width:100%;
        }
        h6 {
            font-size: 15px;
            line-height: 170%;
            text-align: center;
            color: #333F48;
            padding-top: 1rem;
        }
    }
}
/* ================================================================
   Resources Listing Carousel
================================================================ */

.resources-listing-slider {

}
.resources-listing-slider__header {
   @include breakpoint(mw) {
      display: flex;
      align-items: center;
   }
}
.resources-listing-slider__heading {
   @include breakpoint(mw) {
      margin-bottom: 0;
   }
}
.resources-listing-slider__explore-link {
   margin-left: auto;
   font-weight: $fontWeight-bold;
   display: flex;
   align-items: center;

   svg {
      fill: $color-primary;
      width: 16px;
      height: 16px;
      margin-left: 4px;
   }
}

.resources-listing-slider__container {
   padding: 0 0 0 $grid-gutter;

   @include breakpoint(mw) {
      padding: 0 95px;
   }
   @include breakpoint($container-size-large) {
      padding: 0;
      padding-left: calc(50vw - #{$container-size}/2);
   }
}

.resources-listing-slider__item {
   padding: 5px;
   display: flex !important;
   flex-direction: column;

   @include breakpoint(mw) {
      margin-right: 30px;
   }

}

.resources-listing-slider__card-type {
   font-family: $font-secondary;
   text-transform: uppercase;
   letter-spacing: pxtorem(1.75px);
   font-size: pxtorem(14px);
   font-weight: $fontWeight-bold;
   display: inline-block;
   padding: 3px 7px;
   margin-bottom: 15px;
   width: fit-content;

}

.resources-listing-slider__item {
   &:nth-child(1n){
      .resources-listing-slider__card {
         background-color: $color-primary;
         color: $color-invert;
      }
      .resources-listing-slider__card-type {
         background-color: $color-invert;
         color: $color-primary;
      }
      .resources-listing-slider__card-heading {
         color: $color-invert;
      }
   }
   &:nth-child(2n){
      .resources-listing-slider__card {
         background-color: $color-secondary;
         color: $color-invert;
      }
      .resources-listing-slider__card-type {
         background-color: $color-invert;
         color: $color-secondary;
      }
      .resources-listing-slider__card-heading {
         color: $color-invert;
      }

   }
   &:nth-child(3n){
      .resources-listing-slider__card {
         background-color: #F2F2F1;
         color: $color-base;
      }
      .resources-listing-slider__card-type {
         background-color: $color-secondary;
         color: $color-invert;
      }
      .resources-listing-slider__card-heading {
         color: $color-secondary;
      }
   }
}
.resources-listing-slider__card {
   display: flex;
   flex-direction: column;
   height: 100%;
   border-radius: 40px;
   box-shadow: 0 2px 8px rgba(51, 51, 51, 0.25);

}
.resources-listing-slider__figure {
   width: 45%;
   margin-left: auto;
   border-radius: 0 0 0 50%;
   overflow: hidden;
   clip-path: url(#resourcesClip);
}
#resourcesClip {
   height: 0;
   width: 0;
   font-size: 0;
   line-height: 0;
}
.resources-listing-slider__img {
   display:block;
   background: no-repeat center center / cover;
   border-radius: 0 40px 0 0;
   &::after {
       content: '';
       display: block;
       padding-top: percentage(1/1);
   }
}
.resources-listing-slider__card-type {

}
.resources-listing-slider__card {

}
.resources-listing-slider__item:not(.slick-active) {
   position: relative;
   &::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(255,255,255, .5);
   }
}
.resources-listing-slider__card-heading {
   font-size: pxtorem(24px);
   line-height: 1.2;
   margin-bottom: 10px;

   @include breakpoint(mw) {
      font-size: pxtorem(36px);
      margin-bottom: 16px;
   }
}
.resources-listing-slider__card-header + .resources-listing-slider__card-body {
   margin-top: -40px;
   padding-top: 0;
}
.resources-listing-slider__card-body {
   padding: 40px 40px 40px;
   display: flex;
   flex-direction: column;
   height: inherit;

   @include breakpoint(mw) {
      padding-left: 60px;
      padding-bottom: 60px;
   }
}
.resources-listing-slider__card-text {
   font-size: pxtorem(14px);
   margin-bottom: 30px;

   @include breakpoint(mw) {
      font-size: pxtorem(16px);
      margin-bottom: 40px;
   }

}
.resources-listing-slider__card-btn {
   margin-top: auto;
   width: fit-content;
}

/********************************
** Slider Nav
********************************/
.resources-listing-slider__btn {
   @include resetBtn;
   align-items: center;
   width: 90px;
   height: 90px;
   border-radius: 50%;
   display: none;
   z-index: 1;

   justify-content: center;
   background: $color-invert;
   box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2);

   &.slick-disabled {
      display: none !important;
   }

   @include breakpoint(mw) {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
   }

   svg {
      width: 30px;
      height: 30px;
      fill: $color-primary;
   }

   &:hover,
   &:active,
   &:focus {
      background-color: $color-primary;

      svg {
         fill: $color-invert;
      }
   }

   &--prev {
      transform: rotate(180deg);
      left: 10px;

      @include breakpoint($container-size-large) {
         left: calc( 50vw - #{$container-size-large}/2 + 70px);
      }
   }

   &--next {
      right: 10px;
      @include breakpoint($container-size-large) {
         right: 40px;
      }
   }
}
.resources-listing-slider__dots {
   padding: 0 $grid-gutter;
   .slick-dots {
      @include resetList;
      display: flex;
      margin-top: 40px;
   }
   .slick-dots li {
      flex: 1;
      height: 4px;
      display: block;
      background-color: $color-gray;
      font-size: 0;
   }
   .slick-dots li button{
      @include resetBtn;
      width: 100%;
      padding: 0;
      border: 5px solid transparent;
      position: relative;
      top: -3px;
   }
   .slick-dots li.slick-active button {
      border: 5px solid $color-dark-gray;
      border-radius: 5px;
   }
}
.resources-listing-slider {
   .slick-track
   {
       display: flex !important;
   }

   .slick-slide
   {
       height: inherit !important;
   }
}