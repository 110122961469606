#knowledge-tab {
  position: sticky;
  z-index: 97;
  top: 5.7rem;
  //top: 12.5rem;
  .taskbar-nt {
    .wrap {
      display: flex;

      .switch-tab {
        width: calc(100% / 3);
        text-align: center;

        > a {
          color: #71797f;
          text-decoration: none;

          &:hover {
            color: #008065;
          }

          // > i {
          // 	font-size: 2em;
          // }
        }

      }

      .switch-tab.active:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        // width: 0;
        // height: 0;
        // border-left: 10px solid transparent;
        // border-right: 10px solid transparent;
        // // border-bottom: 10px solid #E5E5E5;
        // border-bottom: 10px solid #eaeaeab0;

        width: 20px;
        height: 10px;
        clip-path: polygon(50% 0%, 100% 100%, 0 100%);
        background: #eef2f5;
        filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
      }

      .switch-tab.active {
        > a {
          color: #008065;
        }
      }
    }
  }
}

#knowledge-list {
  ._card {
    border-radius: 0.5rem !important;

    ._more {
      border-top: 1px solid #dee2e6 !important;
      border-radius: 0 0 .5rem .5rem !important;
      font-size: 15px;
    }
  }


  .subject-tl {
    > h3 {
      font-weight: bold;
      font-size: 25px;
      line-height: 22px;
      color: #333F48;
      //text-transform: uppercase;
    }
  }

  .article-special {
    margin-bottom: 1rem;

    .box {
      img.card_thumbnail {
        /* height: 24rem; */
        object-fit: cover;
        // min-height: 28rem;
        // max-height: 28rem;
        min-height: 18rem;
        max-height: 18rem;
      }

      > h4 {
        //text-transform: uppercase;
        font-weight: 700;
        font-size: 28px;
        line-height: 150%;
        color: #003146;
        // min-height: 14.8rem;
        min-height: 10.2rem;
        max-height: 10.2rem;

        > a {
          color: #003146;
          text-decoration: none;
        }

        > a:hover {
          color: #005D7D;
        }
      }

      .article-more {
        background-color: #FCFCFC;

        > a {
          text-decoration: none;
          color: #008264;
          padding: 5px 15px;
          width: 100%;
          display: inline-block;
          font-weight: 300;
          font-size: 15px;
          line-height: 22px;


          &:hover {
            //background-color: #f0f8ff;
            color: #000;

            svg {
              fill: #000;
            }
          }
        }
      }
    }
  }

  .article-item {
    // margin-bottom: 5%;
    .box {
      img.card_thumbnail {
        -o-object-fit: fill;
        object-fit: fill;
        min-height: 9rem;
        max-height: 8rem;
        -o-object-position: center;
        object-position: center;
      }

      > h5 {
        //text-transform: uppercase;
        font-weight: bold;
        font-size: 15px;
        line-height: 150%;
        color: #003146;
        min-height: 7.5rem;

        > a {
          text-decoration: none;
          color: #003146;
        }

        > a:hover {
          color: #005D7D;
        }
      }

      .article-more {
        background-color: #fcfcfc;

        > a {
          text-decoration: none;
          color: #008264;

          padding: 5px 15px;
          width: 100%;
          display: inline-block;

          font-weight: 300;
          font-size: 15px;
          line-height: 22px;

          &:hover {
            // background-color: #f0f8ff;
            color: #000;

            svg {
              fill: #000;
            }
          }
        }
      }
    }
  }
}

.video_div:before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/assets/img/video-play.png) no-repeat 50%;
  background-size: 43px;
  z-index: 9;
  content: "";
}

.item-detail {
  // margin: 0 6.3%;
  // border: 1px solid #ECECEC;
  // box-sizing: border-box;
  // box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  color: #333F48;

  .display-box {
    .video-iframe {
      padding: 3.5% 3.5% 0 3.5%;

      .wrapper {
        img {
          max-width: 100%;
          position: absolute;
          top: 0;
        }

        iframe {
          display: none;
        }

        .play-btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: transparent;
          color: #212216a6;
          border: 0;
          overflow: hidden;

          &:hover {
            color: red;
          }

          > i::before {
            font-size: 4rem;
          }
        }
      }
    }

    .resources-form {
      width: 100%;
      height: 600px;
      display: block;
      position: relative;

      .formHolder {
        #theForm {
          padding: 3rem;

          ul.fade:not(.show) {
            display: none;
          }

          ul {
            list-style: none;
          }
        }
      }

      #thanks {
        position: relative;
        top: 9%;
        z-index: 1000;
        margin-left: 4.9rem;
        margin-right: 6rem;
        background-color: #333f48;
        color: #ffffff;

        h4 {
          color: #ffffff;
        }
      }

      .resources-iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.2;
      }

      .unblock-form {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        > form {
          background-color: #333F48;
          border: 1px solid #666F76;
          box-sizing: border-box;

          > h6 {
            font-family: 'Barlow','Montserrat', sans-serif;
            font-size: 20px;
            line-height: 150%;
            max-width: 500px;
          }

          .form-row {
            .gender {
              color: #ffffff;
            }

            > button {
              width: 100%;
              background-color: #97D700;
              padding: 2% 0;
              font-size: 18px;
              line-height: 21px;
              color: #333F48;
              outline: 0;
            }

            > button:hover {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .detail-wrap {
    padding: 2rem 3rem;
    font-family: 'Barlow','Montserrat', sans-serif;

    > h1 {
      font-weight: 500;
      font-size: 1.8em;
      line-height: 1.3;
    }

    .content {
      > p {
        font-size: 1.1em;
        line-height: 150%;
        text-align: justify;
      }

      .post-image {
        .wrap-img {
          max-width: 70%;
          position: relative;

          .description-img {
            background-color: #EEF1F3;
            font-style: italic;
            font-size: 15px;
            line-height: 170%;
            padding: 0.5rem 2rem;
            color: #707070;

            > p {
              margin-bottom: 0;
            }
          }
        }
      }

      .paragraph {
        ul.relate-link {
          background: #f6fafc;
          padding-top: 1em;
          padding-bottom: 1em;
          padding-right: 1em;
          color: #008065;

          li {
            a {
              font-size: 14.5px;
              color: #337AB7;
            }

            a:hover {
              color: #23527C;
            }
          }
        }
      }
    }

    .share-info {
      // margin-top: 3%;
      > span {
        color: rgba(59, 59, 59, 0.8);
        padding-right: 0.5rem;
      }

      .article-social {
        display: inline-flex;
        // width: 38%;
        > a {
          // width: calc(100% / 3);
          font-size: 0.8em;
          // margin-left: 0.5%;
          // margin-right: 0.5%;
          // padding: 1% 2%;
          margin-right: 0.5rem;
          padding: 0.2rem 0.7rem;
          text-decoration: none;
          font-weight: bold;
          color: #fff;
          vertical-align: middle;
          display: inline-block;
        }

        .facebook {
          background-color: #4267B2;

          &:hover {
            background-color: #6495ED;
          }
        }

        .linkedin {
          background-color: #1177B5;

          &:hover {
            background-color: #5F9EA0;
          }
        }

        .email {
          background-color: #C92228;

          &:hover {
            background-color: #CD5C5C;
          }
        }
      }
    }
  }
}

.zoom img {
  // padding: 50px;
  // background-color: green;
  transition: transform .2s; /* Animation */
  // width: 200px;
  // height: 200px;
  margin: 0 auto;
}

.zoom:hover img {
  transform: scale(1.02);
}

// SLICKS
.item-relate {
  ._card {
    border-radius: 0.5rem !important;

    ._more {
      border-top: 1px solid #dee2e6 !important;
      border-radius: 0 0 .5rem .5rem !important;
    }
  }

  > h4 {
    position: relative;
    top: 30px;
    //padding-left: 2.5%;
    font-family: 'Barlow','Montserrat', sans-serif;
    font-weight: bold;
    font-size: 1.4em;
    line-height: 150%;
    /* or 37px */
    //text-transform: uppercase;
    color: #333F48;
  }

  #slick-article-relate {
    padding-top: 3%;

    .slick-list {
      padding: 0px 0px !important;

      .item {
        // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
        // padding: 0 2%;
        .post_thumbnail {
          // min-height: 206px;
          img.card_thumbnail {
            object-fit: cover;
            min-height: 11rem;
            max-height: 11rem;
            object-position: center;
          }
        }

        .wrap {
          > h6 {
            padding: 4% 3%;
            font-weight: bold;
            font-size: 1em;
            line-height: 150%;
            /* or 24px */
            //text-transform: uppercase;
            color: #003146;
            min-height: 8rem;
          }

          > a {
            font-weight: 300;
            font-size: 1em;
            line-height: 22px;
            /* identical to box height, or 147% */
            //color: #003146;
            color: #008264;
            text-decoration: none;
            display: block;
            background-color: #FCFCFC;
            vertical-align: middle;
            padding: 1% 0% 2% 3%;

            > span {
              color: #003146;
              float: right;
              padding-right: 3%;
            }
          }

          > a:hover {
            color: #000;
          }
        }
      }
    }

    .slick-prev, .slick-next {
      right: 0 !important;
      top: 0;
      left: unset;
      border: 1px solid #ECECEC;
      width: 30px;
      height: 30px;
    }

    .slick-prev {
      margin-right: 4.3%;
    }

    .slick-prev:before {
      content: '❮';
      color: #003146;
      line-height: 0.5;
    }

    .slick-next {
      margin-right: 0.6%;
    }

    .slick-next:before {
      content: '❯';
      color: #003146;
      line-height: 0.5;
    }
  }
}

.youtube-external-subtitle {
  span {
    font-size: 1.5rem;
    background: rgb(61 61 61 / 68%);
  }
}
