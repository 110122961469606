/* Mobile (Ipad) max-width: 425px */
@media all and (max-width: $mobile-width) {
	#knowledge-list .article-item .box>h5 {
        min-height: 3rem;
        font-size: 1rem;
    }
    #knowledge-list .article-special .box .article-more>a, #knowledge-list .article-item .box .article-more>a {
        font-size: 0.8rem;
    }
    // ** End new css

	.msgSuccess > h2,
	.msgSuccess h3,
	.msgSuccess h4,
	.msgSuccess h5 {
		font-size: 0.8rem;
	}

	.item-detail .display-box .resources-form {
		height: 350px;
	}
	// **
	.block_slogan_small {
		font-size: 1em;
	}
	// ** END SLOGAN SMALL
	.dynamic_banner .sl_content_p {
		padding-top: 3rem;
	}

	.dynamic_banner .slii {
		display: none;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 8rem;
	}

	.dynamic_banner .sl-logo {
		padding: 2rem 7rem 0;
	}

	.text_banner_slides {
		font-size: 0.6rem;
	}

	.dynamic_banner .box {
		h6,
		p {
			font-size: 0.8rem;
		}

		h2 {
			// font-size: 1.8rem;
			font-size: clamp(1.8rem,calc(100% + 1vh),1.85rem) !important;
			color: #439bcf;
			line-height: 1.1;

			span.ql-size-large {
				// font-size: 2rem;
				font-size: calc(100% + 1px);
				font-size: clamp(1.74rem,calc(100% + #{$dev-width-size}),2rem)!important;
				margin-left: 0.5rem;
			}

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 2rem;
				position: relative;
				left: 0.4rem;
			}

			sup,
			sup strong {
				font-size: 0.8rem;
			}
		}

		h5 {
			font-size: 0.56rem;
			padding: 0.2rem 0;

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 0.62rem;
				color: #439bcf;
			}
		}

		.btn_flat {
			font-size: 0.8rem;
		}
	}

	.leadership-method {
		//.rwd-line {
		//display: inline-block;
		//content: "\00a0 ";
		//}
		.wrap {
			padding: 0;

			div {
				> h3 {
					//font-size: 2.1rem;
					// font-size: 2rem;
					// font-size: 1.9rem;
					font-size: clamp(1.8rem,calc(100% + 1vh),1.95rem) !important;

					span.ql-size-large {
						// font-size: 2rem;
						// font-size: calc(100% + 1px);
						// font-size: clamp(1.3rem,100% + #{$dev-width-size},2.1rem)!important;
						font-size: clamp(1.9rem,calc(100% + #{$dev-width-size}),2rem)!important;
						// font-size: clamp(10px, 100%, 40px) !important;
						margin-left: 0.5rem;
					}

					sup {
						// font-size: 0.8rem;
						// top: -.8rem;
						// left: -0.2rem;
						top: -0.5rem;
						font-size: 50%;
					}

					> strong {
						-webkit-text-stroke-width: thin;
						font-weight: normal;
						position: relative;
						//font-size: 2.09rem;
						//line-height: 1.5;
						display: block;
						padding-left: 0;
						left: 0.5rem;
						font-size: 2rem;
						line-height: 1;
					}

					> span {
						// display: block;
					}
				}

				> h4 {
					line-height: 1.5em;
					//font-size: 0.64rem;
					font-size: 0.58rem;
					padding: 0.5rem 0;

					strong {
						-webkit-text-stroke-width: thin;
						font-weight: normal;
						font-size: 0.65rem;
						//font-size: 0.72rem;
						display: block;
						margin-top: 0;
						padding: 0;
					}
				}

				> p {
					text-align: justify !important;
					text-align-last: left;
					display: block;
				}

				> h5 {
					font-size: 1.1em;

					> strong {
						font-size: 0.7em;
						display: block;
					}
				}

				> h6 {
					font-size: 0.9em;
				}
			}
		}
	}

	html:lang(en) .dynamic_banner {
		.sl-logo {
			padding-top: 3rem;
		}

		.box h2 {
			span.ql-size-large {
				// font-size: 1.8rem;
				font-size: clamp(1.6rem,calc(100% + #{$dev-width-size}),1.9rem)!important;
				margin-left: 0;
			}

			sup {
				top: -1rem;
			}
		}
	}
	html:lang(en) .leadership-method .wrap h3 {
		// font-size: 1.75rem;
		font-size: clamp(1.7rem,calc(100% + #{$dev-width-size}),1.75rem)!important;
		left: 0.5rem;

		span.ql-size-large {
			// font-size: 1.85rem;
			font-size: clamp(1.7rem,calc(100% + #{$dev-width-size}),1.85rem)!important;
			margin-left: 0;
		}

		sup {
			top: -1rem;
		}
	}

	//**** END DYNAMIC BANNER SLIDER
	.button-component .button-inner .button-second-inner {
		.icon-component {
			width: 21.5px;
			height: 21.5px;
		}

		.button-text {
			padding: 0;
			font-size: 10px;
		}
	}

	.button-component {
		//top: 79.5%;
	}

	.schedule_open table.border-right {
		border: 0!important;
	}

	.btn_flat {
		display: inline-block;
		min-width: 11rem;
	}

	.google_search {
		h3 {
			font-size: 2em;
		}

		.gsc-input {
			.gsc-input-box {
				padding: 0;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			#gsc-i-id1 {
				min-height: 20px;
			}
		}

		.gsc-search-button {
			.gsc-search-button-v2 {
				padding: 0.5rem 1rem;
				margin-left: 10px;
				border-radius: unset;

				svg {
					min-width: 20px;
					min-height: 20px;
				}
			}
		}
	}

	.youtube-external-subtitle {
		top: 9rem !important;

		span {
			font-size: 1em;
		}
	}

	#knowledge-tab {
		.taskbar-nt {
			.wrap {
				.switch-tab {
					> a {
						font-size: 12px;
					}
				}
			}
		}
	}

	#pg-powering-inspired {
		> img {
			min-height: 36rem;
		}

		.mask {
			padding: 1rem 3rem;

			img {
				min-height: 21rem;
			}
		}
	}

	.btn-action {
		display: flex;

		> a {
			text-transform: uppercase;
			color: #333F48;
			background-color: #97D700;
			border: unset;
			text-decoration: none;
			font-size: 0.6;
			padding: 2% 10%;

			&:hover {
				color: #ffffff;
				text-decoration: none;
			}
		}
	}

	.title_ktt {
		font-size: 1.5rem;
	}
	// .btn_flat{
	// 	width: unset;
	// 	min-width: 14rem;
	// }
	.img_top {
		height: 28rem;
		object-fit: cover;
	}

	.object-position-left {
		object-position: left;
	}
	// CAROUSEL
	#carouselBanner {
		.carousel-indicators {
			> li {
				max-width: 0.7rem;
				max-height: 0.7rem;
			}
		}
	}

	#carouselFooterBanner #SLII-slider-caption .wrap-box {
		margin-right: unset;
	}

	#carouselFooterBanner .img_top {
		height: 30rem;
		object-fit: cover;
	}

	.rwd-line {
		display: none;

		&::after {
			content: "\00a0 ";
		}
	}

	#signUpConsultation {
		.modal-content {
			top: 0;

			#consultation-form {
				.modal-header {
					.wrap-bt {
						> button {
							margin-right: 0;
							margin-top: 0;
							padding: 0.10rem 0.4rem;
						}
					}

					.block-form-title {
						> h5 {
							font-size: 1rem;
							line-height: 1.6;

							span {
								font-size: 0.7em;
							}
						}

						> h6 {
							font-size: 0.8em;
						}
					}
				}

				.modal-body {
					padding: 0 1rem;

					.block-reply {
						> h5 {
							font-size: 1rem;
							line-height: 1.6;
						}
					}
				}
			}
		}
	}

	#pg-powering-inspired {
		> img {
			min-height: 24rem;
		}

		.mask {
			padding: 1rem 3rem;
		}
	}

	.model_construction {
		.left-img {
			padding: 0 5rem;
		}

		.right-tile {
			> h2 {
				font-size: 1.1em;
				text-align: -webkit-center;
				line-height: 1.4;
			}

			> h3 {
				font-size: 1em;
				line-height: 0.6;
			}
		}

		.wrap-box-content {
			h5 {
				font-size: 1em;
			}
		}
	}

	header {
		#main-menu {
			background-color: #ffffff;

			.navbar {
				.pace {
					min-width: 6rem;
					margin-right: 1rem;
				}

				.kbv {
					min-width: 8rem;
					margin-left: 0;
				}
			}

			.logo {
				position: unset !important;
				align-items: center;
				display: flex;
				justify-content: center;
				background-color: #ffffff;
			}

			.menu-list {
				background-color: #333F48;

				.menu {
					.nav {
						display: block;
					}

					> ul {
						> li {
							padding: 0.5rem 0;

							> a {
								color: #ffffff;
							}
						}
					}

					.social-mobile {
						.googlesearch {
							.search {
								svg {
									margin-bottom: 0.3rem;
								}
							}

							.space {
								border-right: solid 1px #ffffff;
								margin-left: 1rem;
								margin-right: 1rem;
							}
						}

						.network-social {
							padding-right: 0.7rem;

							.flag-2 {
								color: #ffffff;
							}
						}

						.language {
							> a {
								color: #ffffff;
								text-transform: uppercase;
							}

							.space {
								border-left: 1px solid #ffffff;
								margin-right: 1rem;
								content: '';
							}

							> a.active {
								color: #97d700;
							}
						}
					}
				}
			}
		}
	}
	// Intro page
	#introduce {
		.intro-box {
			.logo-img {
				a {
					> img {
						max-width: 70%;
					}
				}
			}
		}
	}

	.view {
		img {
			object-fit: cover;
			vertical-align: middle;
			min-height: 38rem;
		}

		.mask {
			> img {
				padding: 1rem 5rem;
				max-width: 100%;
				min-height: unset;
			}

			.slii-content {
				padding: 1% 2%;
				text-align: justify;

				p {
					text-align: justify !important;
				}
			}
		}
	}
	/* Force table to not be like tables anymore */
	.table_responsive {
		table,
		tbody,
		td,
		th,
		thead,
		tr {
			display: block;
		}
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

		tr {
			border: 1px solid #ccc;
		}

		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 40%;
			white-space: normal;
			text-align: left;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
			text-align: left;
			font-weight: bold;
		}
		/*
		Label the data
		*/
		td:before {
			content: attr(data-title);
		}
	}

	.slogan-big {
		.wrap {
			text-align: justify;
		}
	}

	.schedule_open {
		.title {
			> h3 {
				font-size: 2em;
			}
		}

		.date {
			.bg {
				// margin: 0 6.229rem;
				margin: 0 3.5rem;
			}
		}

		.heading {
			> h4 {
				text-align: center;
				font-size: 1.1em;
				line-height: 1.4;
			}
		}

		.content table tbody tr td,
		.content table tbody tr th {
			font-size: 1em;
			display: block;
		}

		.shadow-box {
			margin: 0 0.1rem;
			padding: 0 0.5rem 2rem;
		}

		.register {
			padding-top: 0.6rem;

			.course-act {
				> a {
					padding: 0.3rem 0;
					font-size: 0.8em;
					margin: 1rem 3rem;
				}
			}
		}
		// .register::before {
		//     border-left: unset;
		//     border-top: solid 1px #C1D8E5;
		//     min-width: 17rem;
		//     min-height: unset;
		//     top: 0;
		//     right: 1.9rem;
		// }
	}

	.partners-world {
		.partners-world-slides {
			padding: 0 3rem;
		}
	}

	.leadership_benefit {
		.container {
			> h3 {
				font-size: 1.1em;
				line-height: 2.1;

				> u {
					background-color: #333f48;
					color: #ffffff;
					display: block;
					text-decoration-line: none;
				}
			}

			.nb-text-item {
				.rwd-line {
					display: block;
				}
			}

			.bn-txt {
				font-size: 1.2rem;
				line-height: 160%;

				> span {
					display: inline-block;
				}
			}

			.nb-icon {
				img {
					max-width: 50%;
				}
			}
		}
	}

	.plan {
		min-height: 200px;

		.container-wrap .left,
		.container-wrap .right {
			min-height: 200px;
			float: left;
		}

		.wrap {
			width: 100%;
			padding: 2rem 1rem;
			max-height: 22rem;

			.pl-content {
				> h3 {
					text-align: center;
					font-size: 0.9rem;
					line-height: 2.1;

					> u {
						display: block;
					}
				}

				> ul {
					text-align: justify;
					padding-inline-start: 20px;
				}
			}
		}

		.plan-bg {
			display: none;

			img {
				max-width: unset;
				object-fit: cover;
			}
		}
	}

	#all_ready {
		.ar-title {
			font-size: 1.4em;
		}

		.ar-content {
			padding: unset;
			text-align: justify;

			ul {
				padding-inline-start: 20px;
			}
		}
	}

	.partners-world {
		.partners-world-title {
			font-size: 22px;
			line-height: 150%;
		}

		.partners-sub {
			padding-left: 3%;
			padding-right: 3%;
		}
	}
	// SLIDER
	.slider_marketing {
		h2 {
			font-size: 1.5em;
		}

		.wrap {
			padding: 0;

			h3 {
				font-size: 1.44em;
			}

			h4 {
				line-height: 1.5;

				strong {
					display: block;
					padding-top: 0;
					margin-top: 0;
				}
			}
		}
	}
	// KHO HOC LIEU PAGE
	#knowledge-list {
		img.card_thumbnail {
			min-height: 14rem!important;
			max-height: 14rem!important;
		}

		.article-special {
			.box {
				> h4 {
					min-height: 3rem;
					font-size: 0.95rem;
				}
			}
		}

		.article-item {
			.box {
				> h5 {
					min-height: 3rem;
				}
			}
		}
	}

	.item-detail {
		.display-box {
			.resources-form {
				.formHolder {
					#theForm {
						padding: 1rem;

						> h6 {
							font-size: 1em;
							text-align: justify;
							text-align-last: center;
						}

						.form-row {
							> button {
								font-size: 1em;
							}

							.gender {
								.invalid-feedback,
								.valid-feedback {
									position: relative;
									min-width: 200px;
									right: 310%;
								}
							}
						}
					}
				}

				#thanks {
					margin-left: 1rem;
					margin-right: 1rem;
				}
			}
		}

		.detail-wrap {
			padding: 2rem 1rem;
			font-family: 'Montserrat', sans-serif;

			> h1 {
				font-size: 1.2em;
				line-height: 1.3;
			}

			> hr {
				margin-top: 0;
			}

			.content {
				.paragraph {
					> p {
						font-size: 0.9em;
					}
				}

				.post-image {
					.wrap-img {
						max-width: 100%;

						.description-img {
							font-size: 0.9em;
						}
					}
				}
			}

			.share-info {
				// margin-top: 3%;
				> span {
					color: rgba(59, 59, 59, 0.8);
				}

				.article-social {
					width: 100%;

					> a {
						font-size: 0.6em;
						margin: 0 0.1rem;
					}
				}
			}
		}
	}

	.item-relate {
		> h4 {
			top: 0.8rem;
			font-size: 1em;
		}

		#slick-article-relate {
			.slick-next,
			.slick-prev {
				top: -0.6rem;
				width: 25px;
				height: 25px;
			}

			.slick-prev {
				margin-right: 8.3%;
			}

			.slick-list {
				.slick-track {
					.slick-slide {
						.item {
							.wrap {
								> h6 {
									font-size: 0.8em;
									min-height: 5rem;
								}
							}
						}
					}
				}
			}
		}
	}
	// EVENTS
	#event-list {
		.wrap {
			> h4 {
				font-size: 1em;
				text-align: justify;
			}
		}
	}

	#event-detail {
		.wrap-box {
			margin: 2% 0;
			text-align: justify;

			.event-table {
				.table {
					th {
						font-size: 1em;
						min-width: 5rem;
					}

					td {
						font-size: 1em;
					}
				}
			}

			> h1 {
				font-size: 1.2em;
			}

			.paragraph {
				> h3 {
					font-size: 1em;
				}
			}
		}
	}
	// REGISTER
	#register-fr {
		.re-txt {
			> h2 {
				font-size: 0.9rem;
				line-height: 1.7;
				font-weight: 600;
			}
		}

		#register-form {
			.form-inline {
				> label {
					padding-left: 0;
					font-size: 0.92em;
				}
			}
		}
	}
	// CONTACT
	.contact-block {
		.h-txt {
			font-size: 0.9rem;
			font-weight: 600;
		}

		#contact-form {
			.form-inline {
				> label {
					padding-left: 0;
				}

				> button {
					background-color: #97D700;
					padding: 1% 0;
				}

				> button:hover {
					color: #ffffff;
				}
			}
		}

		.wrap {
			.contact_info {
				background-color: #E5EFF6;

				> h3 {
					font-weight: bold;
					font-size: 20px;
					line-height: 26px;
					text-transform: uppercase;
					color: #7BAFD4;
					border-left: solid 5px #7BAFD4;
				}
			}

			.map {
				> iframe {
					min-height: 350px;
				}
			}
		}
	}
	// SLIDER
	#slider-contact,
	#slider-home,
	#slider-intro {
		.wrap-caption {
			margin-top: 0;
			margin-right: 0;
			padding: 0 1rem;

			h3 {
				font-size: 0.8em;
				line-height: 2.2;
				font-weight: 600;

				.rwd-line {
					display: block;
				}

				> u {
					padding: 0 0.1rem;
					display: inline-block;
				}
			}

			.logo-img {
				> img {
					min-width: 60%;
				}
			}
		}
	}
	// FOOTER PAGE
	footer {
		.top-ft {
			.ft-title {
				> h4 {
					font-size: 16px;
				}
			}

			.address-contact {
				padding-bottom: 0.6rem;
			}
		}
	}

	.signUpConsultation .modal-content .consultation-form .modal-header .block-form-title > h5,
	.signUpConsultation .modal-content .consultation-form .modal-header .block-reply > h5 {
		font-size: 1rem;
	}

	.signUpConsultation .modal-content .consultation-form .modal-header .wrap-bt > button {
		margin-right: -1.5rem;
		margin-top: -1rem;
	}

	.leadership_benefit h3 u {
		display: block;
	}

	.leadership-bnt {
		padding-top: 1rem;
	}
}
/* Mobile (Ipad) max-width: 375px */
//@media all and ( max-width: 375px) {
/* Mobile browsers only */
@media only screen and (max-device-width: 375px) {
	.schedule_open .heading>h4{
        font-size: 1rem;
    }
    .schedule_open .title>h3 {
        font-size: 1.4rem;
    }
    // ** End new css
	.contact-block .h-txt {
		font-size: 1.5rem;
	}

	.dynamic_banner .slii {
		display: none;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 6rem;
	}

	.dynamic_banner .sl-logo {
		padding: 2rem 7rem 0;
		max-height: 7rem;
	}

	.dynamic_banner .sl_content {
		align-self: unset;
	}

	.text_banner_slides {
		font-size: 1rem;
	}
	// .dynamic_banner .box {
	// 	h2 {
	// 		font-size: 1.8rem;
	// 		color: #439bcf;
	// 		line-height: 1;
	//
	// 		strong {
	// 			-webkit-text-stroke-width: thin;
	// 			font-weight: normal;
	// 			font-size: 2rem;
	// 			left: 0.5rem;
	// 			position: relative;
	// 		}
	//
	// 		sup,
	// 		sup strong {
	// 			font-size: 0.8rem;
	// 		}
	// 	}
	//
	// 	h4 strong {
	// 		font-size: 1.2rem;
	// 	}
	//
	// 	h5 {
	// 		font-size: 0.55rem;
	// 		padding: 0.2rem 0;
	//
	// 		strong {
	// 			-webkit-text-stroke-width: thin;
	// 			font-weight: normal;
	// 			font-size: 0.62rem;
	// 			color: #439bcf;
	// 		}
	// 	}
	// }
	//
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 1.8rem;
	// 	left: 0.5rem;
	// 	// sup {
	// 	// 	top: -0.9rem;
	// 	// }
	// }
	//**** END DYNAMIC BANNER SLIDER
	// .leadership-method {
	// 	.wrap {
	// 		padding: 0;
	//
	// 		div {
	// 			> h3 {
	// 				line-height: 0.5;
	// 				font-size: 1.8rem;
	//
	// 				strong {
	// 					-webkit-text-stroke-width: thin;
	// 					font-weight: normal;
	// 					font-size: 1.86rem;
	// 					left: 0.4rem;
	//
	// 					sup {
	// 						font-size: 0.8rem;
	// 					}
	// 				}
	//
	// 				.rwd-line {
	// 					display: inline-block;
	// 				}
	// 			}
	//
	// 			> h4 {
	// 				padding: 0.5rem 0;
	// 				font-size: 0.59rem;
	//
	// 				> strong {
	// 					-webkit-text-stroke-width: thin;
	// 					font-weight: normal;
	// 					font-size: 0.65rem;
	// 				}
	//
	// 				.rwd-line {
	// 					display: none;
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	//
	// html:lang(en) .leadership-method .wrap h3 {
	// 	font-size: 1.65rem;
	// 	left: 0.4rem;
	//
	// 	sup {
	// 		top: -0.8rem;
	// 	}
	// }
	//**** END EADRERSHIP METHOD
	#carouselFooterBanner .img_top {
		height: 25rem;
	}

	.signUpConsultation .modal-content .consultation-form .modal-header .block-form-title > h5,
	.signUpConsultation .modal-content .consultation-form .modal-header .block-reply > h5 {
		font-size: 0.8rem;
	}

	.model_construction .right-tile > h2 {
		font-size: 0.85rem;
		margin-bottom: 0.5rem;
	}

	.model_construction .wrap-box-content h5 {
		font-size: 0.9em;
	}

	.leadership_benefit .container > h3 {
		font-size: 0.9rem;
	}
}
/* Mobile (Ipad) max-width: 360px */
@media all and (max-width: 360px) {
	#register-fr .re-txt > h2 {
		font-size: 0.8rem;
	}

	.msgSuccess {
		h2,
		h3,
		h4,
		h5 {
			font-size: 0.7rem;
		}
	}

	.item-detail .display-box .resources-form {
		height: 440px;
	}
	// **
	.leadership-method .wrap div {
		h3 {
			font-size: 1.7em;

			.rwd-line {
				display: inline-block;
			}

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 0.95em;
			}
		}

		h4 {
			font-size: 0.5rem;

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 0.56rem;
			}
		}
	}

	.leadership-method .wrap div > h5 {
		line-height: 1.6;
	}

	.leadership-method .wrap .ld-img {
		margin-top: 3rem;
	}

	html:lang(en) .leadership-method .wrap h3 {
		font-size: 1.4rem;
		left: 0.5rem;
	}
	// html:lang(en) .dynamic_banner .box h2 {
	// 	font-size: 1.75rem;
	// }
	.partners-world .partners-world-title {
		font-size: 1.2em;
	}
}
@media only screen and (max-width: 320px) {
	.img_top {
		height: 23rem;
	}

	.dynamic_banner .sl_logo {
		padding: 2rem 4rem;
	}

	.dynamic_banner .sl-logo {
		padding: 3rem 5rem 0;
	}

	.dynamic_banner .sl_content {
		padding-top: 2.5rem;
	}

	.dynamic_banner .box {
		h2 {
			// font-size: 1.52rem;
			font-size: clamp(1.4rem,calc(100% + #{$dev-width-size}),1.52rem)!important;
			color: #439bcf;
			line-height: 1;

			span.ql-size-large {
				// font-size: 1.85rem;
				font-size: clamp(1.3rem,calc(100% + #{$dev-width-size}),1.6rem)!important;
				margin-left: 0;
			}
			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 1.7rem;
				position: relative;
				left: 0.4rem;
			}

			sup,
			sup strong {
				font-size: 0.8rem;
				top: -0.6rem;
			}
		}

		h5 {
			font-size: 0.47rem;

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 0.53rem;
				color: #439bcf;
			}
		}

		h6 {
			font-size: 0.7rem;

			strong {
				color: #439bcf;
			}
		}
	}

	html:lang(en) .dynamic_banner .box h2 {
		// font-size: 1.53rem;
		font-size: clamp(1.5rem,calc(100% + #{$dev-width-size}),1.53rem)!important;
		span.ql-size-large {
			// font-size: 1.85rem;
			font-size: clamp(1.3rem,calc(100% + #{$dev-width-size}),1.6rem)!important;
			margin-left: 0;
		}
	}
	//*** END DYNAMIC BANNER SLIDER*/
	.leadership-method .wrap .ld-img {
		margin-top: 0;
	}

	.leadership-method .wrap div {
		h3 {
			// font-size: 1.6rem;
			font-size: clamp(1.5rem,calc(100% + #{$dev-width-size}),1.6rem)!important;
			line-height: 0.5;

			span.ql-size-large {
				// font-size: 1.85rem;
				font-size: clamp(1.3rem,calc(100% + #{$dev-width-size}),1.6rem)!important;
				margin-left: 0;
			}

			.rwd-line {
				display: inline-block;
			}

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 1.5rem;
				left: 0.4rem;
			}
		}

		h4 {
			font-size: 0.5rem;

			strong {
				-webkit-text-stroke-width: thin;
				font-weight: normal;
				font-size: 0.56rem;
			}
		}
	}

	html:lang(en) .leadership-method .wrap h3 {
		// font-size: 1.38rem;
		font-size: clamp(1.3rem,calc(100% + #{$dev-width-size}),1.38rem)!important;
	}

	.model_construction .right-tile {
		h2 {
			font-size: 0.8rem;
		}

		h3 {
			font-size: 0.9em;
		}
	}

	.leadership_benefit .nb-text-item {
		font-size: 0.8rem;
	}

	.leadership_benefit .container > h3 {
		font-size: 0.76rem;
	}
}
