

// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,400italic,500,500italic,700italic');
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

// Variables
@import 'kbv/_variables';
@import 'kbv/_utilities';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';

@import '~aos/src/sass/aos';
@import '~animate.css/animate.compat.css';

$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import '~plyr/dist/plyr.css';

body {
	font-size: 15px;
	line-height: 1.6;
	background-color: #FCFCFC;
	font-family: $font-family-open-sans;
	position: relative;
}

*:focus {
  outline: 0;
  outline: none;
}

.ql-align-right{
  text-align:right !important;
}
.ql-align-left{
  text-align:left !important;
}
.ql-align-center{
  text-align: center !important;
}
.error-box {
	background: #FBF5F5;
	border: 1px solid #ED4E43;
	box-sizing: border-box;
}
.success-box {
	background: #F1F7F2;
	border: 1px solid #28A244;
	box-sizing: border-box;
}
.dynamic_banner .slii img{
	width: unset!important;
	max-width: unset;
	height: 100%;
}

$dev-width-size: 1vh;
// html:lang(en) {
// 		.dynamic_banner .box h2,
// 		.leadership-method .wrap h3 {
// 			font-size: clamp(1.3rem, 100% + #{$width_size}, 5rem)!important;
// 			margin-bottom: 0px!important;
//			 span.ql-size-large{
//
//	 }
// 	}
// }
//
//
// .dynamic_banner .box h2,
// .leadership-method .wrap h3 {
// 			font-size: clamp(1.3rem, 100% + #{$width_size}, 5rem)!important;
// 			margin-bottom: 0px!important;
// 	 span.ql-size-large {
//
// 	 }
// }

// KBV layout
@import 'kbv/header.scss';
@import 'kbv/slides.scss';
@import 'kbv/footer.scss';

// KBV pages
@import 'kbv/pages/home';
@import 'kbv/pages/intro';
@import 'kbv/pages/program';
@import 'kbv/pages/knowledgecenter';
@import 'kbv/pages/event';
@import 'kbv/pages/contact';

// KBV breakpoints
@import 'kbv/breakpoints/desktop';
@import 'kbv/breakpoints/tablet';
@import 'kbv/breakpoints/mobile';

@import 'custom_kbv';
// @import 'kbv/breakpoints/mobile';
// @import 'kbv/breakpoints/tablet';
// @import 'kbv/breakpoints/desktop';
